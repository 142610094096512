import { useShoppingCart } from "../Context/shoppingCartContext";
import { CartItem } from "./cartItem";
import closeImage from "../assets/images/close.png";

export function ShoppingCart({ isOpen }) {
  const { closeCart, cartItems, products } = useShoppingCart();

  return (
    <div
      className="slide-in-cart-container"
      style={{
        right: isOpen ? 0 : "-100%",
        display: isOpen ? "flex" : "none",
        background: "white",
        transition: ".3s",
      }}
    >
      <img
        onClick={() => {
          closeCart();
        }}
        src={closeImage}
        loading="lazy"
        alt=""
        className="slide-in-cart-close-button"
      />
      {cartItems.map((item) => {
        return <CartItem key={item.name} {...item} products={products} />;
      })}
      <button href="/checkout" className="button-23 ">
        Checkout
      </button>
    </div>
  );
}
