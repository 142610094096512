const initialState = {
  alertShown: false,
  missingInfo: false,
  _id: "",
  lastDate: 0,
};

export default function preferences(state = initialState, action) {
  switch (action.type) {
    case "RESET_DATE":
      return initialState;
    case "UPDATE_ALERT_SHOWN":
      return { alertShown: true };
    case "UPDATE_LAST_DATE":
      return { lastDate: Date.now() };
    case "MISSING_INFO":
      return { missingInfo: true, _id: action._id };
    default:
      return state;
  }
}
