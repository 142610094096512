import * as React from "react";

let token =  localStorage.getItem('authToken');

const authContext = React.createContext({
  authed: token ? true : false,
  login: () => {},
  logout: () => {}
});

export default function UseAuth() {
  return React.useContext(authContext);
}

export function AuthProvider({ children }) {
  const [authed, setAuthed] = React.useState(token ? true : false);

  const login = () => setAuthed(true);
  const logout = () => setAuthed(false);

  let value = { authed, login, logout };


  return (
    <authContext.Provider value={value}>
      {children}
    </authContext.Provider>
  );
}