import React from "react";

export default function Breadcrumb(props) {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "0px 10px",
        margin: "0px",
        zIndex: "99",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <p
        style={{
          fontWeight: "800",
          margin: "0px",
          padding: "10px",
        }}
      >
        /{props.title}
      </p>
    </div>
  );
}
