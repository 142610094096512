import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import DashboardNav from "../Dashboard/dashboardNav";
import Modal from "react-modal";

const mainPanel = {
  paddingBottom: "60px",
  width: "100%",
  backgroundColor: "#e3e3e3",
  height: "100%",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function ScheduleManager() {
  let subtitle;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState({
    timezone: "est",
    type: "visit",
  });
  const [modalIsOpen, setIsOpen] = useState(false);

  Modal.setAppElement("#root");

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    axios.get("/api/events/events").then((res) => {
      setEvents(res.data);
      setLoading(false);
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    axios.post("/api/events/event", eventDetails).then((res) => {
      setLoading(true);
      setIsOpen(false);
      setEventDetails({
        timezone: "est",
        type: "visit",
      });

      axios
        .post(
          "https://www.medspanetwork.com/wp-json/wp/v2/tribe_events",
          eventDetails,
          {
            auth: {
              username: "Keaton",
              password: "ksln Gv4h GJ2p sRCJ oir1 vNXJ",
            },
          }
        )
        .then((res) => {
          console.log(res);
          axios.get("/api/events/events").then((res) => {
            setEvents(res.data);
            setLoading(false);
          });
        });
    });
  };

  const eventList = events.map((event, index) => {
    let color;
    let image;

    switch (event.type) {
      case "visit":
        color = "skyblue";
        break;

      case "webinar":
        color = "goldenrod";
        break;

      case "in-office":
        color = "#c32389";
        break;

      default:
        break;
    }

    return (
      <li
        key={index}
        style={{
          gap: "30px",
          borderBottom: "1px solid grey",
          flexDirection: "row",
          width: "100%",
          display: "flex",
          alignItems: "center",
          background: "white",
          borderLeft: "20px solid " + color,
        }}
      >
        <div
          style={{
            height: "100%",
            background: color,
            padding: "60px 0px",
            maxWidth: "30px",
          }}
        >
          <p style={{ transform: "rotate(-90deg)", width: "100%" }}>
            {/* {event.type} */}
          </p>
        </div>
        <div style={{ width: "33%", maxWidth: "200px" }}>
          <h1>{moment(event.startDate).format("MMMM DD")}</h1>
        </div>
        <div>
          <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
            <h2 style={{ margin: "0px" }}>{event.title}</h2>
          </div>
          <div>
            <h4>
              {moment(event.startDate).format("MMMM DD YYYY,  hh:MM A") +
                " - " +
                moment(event.endDate).format("MMMM DD YYYY, hh:MM A") +
                " " +
                event.timezone}
            </h4>
          </div>
        </div>
      </li>
    );
  });

  return (
    <div>
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
        }}
      >
        <DashboardNav />

        <div className="dashboardMainPanel" id="adminPanel">
          <div
            style={{
              backgroundColor: "white",
              padding: "0px 10px",
              margin: "0px",
              zIndex: "99",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "800",
                margin: "0px",
                padding: "10px",
              }}
            >
              / Event Schedule
            </p>
            <button
              style={{ width: " 150px" }}
              onClick={(e) => {
                setIsOpen(true);
              }}
            >
              {" "}
              + Add Event
            </button>
          </div>
          <div style={mainPanel}>
            {loading ? (
              <h1>Loading</h1>
            ) : (
              <ul style={{ margin: "0px" }}>{eventList}</ul>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add User"
        overlayClassName="Overlay"
      >
        <div
          style={{
            width: "300px",
            height: "100%",
            background: "lightgrey",
            padding: "30px",
          }}
        >
          <form onSubmit={(e) => onSubmit(e)}>
            <div style={{ margin: 20 }}>
              <label>Title: </label>
              <input
                required
                id="title"
                onChange={(e) => {
                  setEventDetails((prevState) => ({
                    ...prevState,
                    title: e.target.value,
                  }));
                }}
              />
            </div>
            <div style={{ margin: 20 }}>
              <label>Type of Event: </label>
              <select
                required
                onChange={(e) => {
                  setEventDetails((prevState) => ({
                    ...prevState,
                    type: e.target.value,
                  }));
                }}
              >
                <option value="visit">Visit</option>
                <option value="webinar">Webinar</option>
                <option value="in-office">In-Office Training</option>
              </select>
            </div>
            <div style={{ margin: 20 }}>
              <label>Start Date: </label>
              <input
                required
                type="datetime-local"
                onChange={(e) => {
                  setEventDetails((prevState) => ({
                    ...prevState,
                    startDate: e.target.value,
                  }));
                }}
              />
            </div>
            <div style={{ margin: 20 }}>
              <label>End Date: </label>
              <input
                required
                type="datetime-local"
                onChange={(e) => {
                  setEventDetails((prevState) => ({
                    ...prevState,
                    endDate: e.target.value,
                  }));
                }}
              />
            </div>
            <div style={{ margin: 20 }}>
              <button type="submit">Add to Schedule</button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
