import { createContext, useContext } from "react";
import { UseLocaleStorage } from "../Hooks/useLocalStorage";

const CompanyInformationContext = createContext({});

export function useCompany() {
  return useContext(CompanyInformationContext);
}

export function CompanyInformationProvider({ children }) {
  const [companyInformation, setCompanyInformation] = UseLocaleStorage(
    "CompanyInformation",
    []
  );

  function setCompany(value) {
    setCompanyInformation(value);
  }

  function getCompany() {
    return companyInformation;
  }

  function resetCompany() {
    setCompanyInformation([]);
  }

  return (
    <CompanyInformationContext.Provider
      value={{
        companyInformation,
        setCompany,
        getCompany,
      }}
    >
      {children}
    </CompanyInformationContext.Provider>
  );
}
