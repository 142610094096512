import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Header from "../../Components/Header";
import { init } from "emailjs-com";
import { useSnackbar } from "notistack";
import Moment from "moment";
import AdminTiles from "../../Components/View/components/adminTiles";
import LoadAdminTiles from "../../Components/View/components/loadAdminTiles";
import DashboardNav from "../../Components/Dashboard/dashboardNav";
import "../../Components/View/paginationStyles.css";
import ReactPaginate from "react-paginate";
import sendEmails from "../Functions/emails";
import { intToDollars } from "../Functions/intConversions";

init("user_m9sQKY2UWwjnhd6strAmN");

export default function AdminCenter() {
  ////////////////////////////////////
  //  Setup variables
  ////////////////////////////////////
  var fileDownload = require("js-file-download");
  const firstUpdate = useRef(true);

  const [allPurchases, setAllPurchases] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [quarterTotal, setQuarterTotal] = useState(0);
  const [requests, setRequests] = useState({});
  const [count, setCount] = useState(0);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [searchField, setSearchField] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);

  const itemsPerPage = 20;
  let endOffset = itemOffset + itemsPerPage;

  useEffect(() => {
    setLoading(true);
    endOffset = itemOffset + itemsPerPage;
    setCurrentItems(allPurchases.slice(itemOffset, endOffset));
    setPageCount(
      Math.ceil(allPurchases.length / itemsPerPage),
      setLoading(false)
    );
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [itemOffset, itemsPerPage, allPurchases]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allPurchases.length;
    setItemOffset(newOffset);
  };

  function Items({ currentItems }) {
    return currentItems.length > 0 ? (
      currentItems.map((eachPurchase, index) => {
        return (
          <AdminTiles
            key={index}
            details={eachPurchase}
            index={index}
            trackingNumber={trackingNumber}
            setTrackingNumber={setTrackingNumber}
            confirmShipping={confirmShipping}
            orderRecieptEmail={orderRecieptEmail}
            handleClick={handleClick}
          />
        );
      })
    ) : (
      <div></div>
    );
  }

  const mainPanel = {
    paddingBottom: "60px",
    width: "100%",
    backgroundColor: "#e3e3e3",
    overflowY: "scroll",
  };

  let emailCart = [];

  ////////////////////////////////////
  //  Setup Snackbar
  ////////////////////////////////////
  // const { enqueueSnackbar } = useSnackbar();
  const handleClick = (data) => {
    // enqueueSnackbar(data.text, { variant: data.type });
  };

  ////////////////////////////////////
  //  Gather list of companies on load
  ////////////////////////////////////
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setLoading(true);
    let price = 0;

    axios
      .post("https://www.medspanetwork.com/api/orders/getPurchases")
      .then((res) => {
        console.log(res);
        setAllPurchases(res.data);
        setCount(res.data.length);

        let quarterTotal = 0;
        var today = new Date();
        var thisQuarter = Number(Math.floor((today.getMonth() + 3) / 3));
        res.data.forEach((eachOrder) => {
          price = Number(eachOrder.amount) + price;
        });

        res.data.forEach((eachOrder) => {
          let orderMonth = Math.floor(
            (Number(Moment(eachOrder.date).format("MM") - 1) + 3) / 3
          );

          if (
            orderMonth === thisQuarter &&
            Moment(eachOrder.date).format("YY") ===
              Moment(Date.now()).format("YY")
          ) {
            quarterTotal = quarterTotal + Number(eachOrder.amount);
          }
        });

        setQuarterTotal(quarterTotal);
      })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        handleClick({
          type: "error",
          text: err.text,
        });
      });
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    axios
      .post("/api/orders/getAutocompleteCompanyNames", { search: searchField })
      .then((res) => {
        setSuggestions(res.data);
      });
  }, [searchField]);

  ////////////////////////////////////
  //  CREATE and SEND emails out on purchase
  ////////////////////////////////////
  const orderRecieptEmail = (details) => {
    // //Setup the HTML table for the products at the bottom of the email.
    Object.keys(details.cart).forEach((item) => {
      let tier;
      switch (details.tier) {
        case "3":
          tier = "tierThreePrice";
          break;

        case "4":
          tier = "tierFourPrice";
          break;

        case "5":
          tier = "tierFivePrice";
          break;

        default:
          break;
      }

      if (details.cart[item] !== 0) {
        emailCart.push(`<html>
        <table width="600" style="border:1px solid #333">
          <tr>
            <td align="center">Product</td>
            <td align="center">Amount Ordered</td>
            <td align="center">Product Price</td>
            <td align="center">Total</td>
          </tr>
          <tr>
            <td align="center" style="color: black">${item}</td>
            <td align="center" style="color: black">${
              details.cart[item].ordered
            }</td>
            <td align="center" style="color: black">${intToDollars(
              details.cart[item].productInfo[tier]
            )}</td>
            <td align="center" style="color: black">${intToDollars(
              details.cart[item].productInfo[tier] * details.cart[item].ordered
            )}</td>
          </tr>
        </table>
      </html>`);
      }
    });

    const total = intToDollars(Number(details.amount));

    //Use the EMAILJS api to send an email out to everyone
    window.emailjs
      .send("service_k7n5kjk", "template_tm1vdhl", {
        email_to: [details.repEmail, details.email],
        message: "There is a new order of: ",
        from_name: "Medspa Network Orders",
        reply_to: ["sales@medspanetwork.com", ""],
        to_name: "Medspa Network",
        my_html: emailCart.join(""),
        accountNumber: details.accountNumber,
        company: details.company,
        firstName: details.firstName,
        lastName: details.lastName,
        purchaserEmail: details.email,
        purchaserPhone: details.purchaserPhone,
        attn: details.attn,
        shippingPhone: details.shippingPhone,
        shippingAddress: details.shippingAddress,
        shippingCity: details.shippingCity,
        shippingState: details.shippingState,
        shippingZipcode: details.shippingZipcode,
        poBox: details.poBox,
        repEmail: details.repEmail,
        repPhone: details.repPhone,
        total: total,
      })
      .then((res) => {
        console.log("Email successfully sent!");
        handleClick({
          type: "success",
          text: "Email successfully sent!",
        });
        // document.location.reload();
      })
      // Handle errors
      .catch((err) => {
        //TODO make this better, error should show up on screen
        handleClick({
          type: "error",
          text: "Email Not sent!",
        });
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
      });
  };

  const exportFile = (data) => {
    axios
      .post("/api/export/excel", { data: data }, { responseType: "blob" })
      .then((response) => {
        fileDownload(response.data, `excel${Date.now()}.xlsx`);
      });
  };

  ////////////////////////////////////
  //  Change shipping status and return the new values to be emailed to the customer.
  ////////////////////////////////////
  const confirmShipping = (_id, heldTrackingNumber) => {
    axios
      .post("/api/orders/confirmshipping", {
        id: _id,
        trackingNumber: heldTrackingNumber,
      })
      .then((res) => {
        sendEmails({
          data: res.data.value,
          shippingStatusChange: true,
          handleClick,
        });
        handleClick({
          type: "success",
          text: "Email successfully sent!",
        });
      });
  };

  ////////////////////////////////////
  //  Set Filters
  ////////////////////////////////////
  const filter = (request) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setLoading(true);
    let price = 0;

    axios
      .post("/api/orders/getPurchases", {
        request,
      })
      .then((res) => {
        setAllPurchases(res.data);
        setCount(res.data.length);

        res.data.forEach((purchase) => {
          price = Number(purchase.amount) + price;
        });

        let quarterTotal = 0;
        var today = new Date();
        var thisQuarter = Number(Math.floor((today.getMonth() + 3) / 3));

        res.data.map((eachOrder) => {
          let orderMonth = Math.floor(
            (Number(Moment(eachOrder.date).format("MM") - 1) + 3) / 3
          );

          if (
            orderMonth === thisQuarter &&
            Moment(eachOrder.date).format("YY") ===
              Moment(Date.now()).format("YY")
          ) {
            quarterTotal = quarterTotal + Number(eachOrder.amount);
          }
          return 0;
        });

        setQuarterTotal(quarterTotal);
      })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        handleClick({
          type: "error",
          text: err.text,
        });
      });
  };

  ////////////////////////////////////
  //  RENDER
  ////////////////////////////////////
  return (
    <div style={{ maxHeight: "100vh", overflowY: "hidden" }}>
      <Header style={{ height: "100%", position: "relative" }} />
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
        }}
      >
        <DashboardNav />

        <div
          className="dashboardMainPanel"
          id="adminPanel"
          style={{ overflowY: "scroll" }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "0px 10px",
              margin: "0px",
              zIndex: "99",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "800",
                margin: "0px",
                padding: "10px",
              }}
            >
              / All Purchases
            </p>
            <div>
              <div style={{ display: "flex", gap: "20px" }}>
                <h3 style={{ margin: "0px", fontWeight: "400" }}>
                  This Semester's Total:
                </h3>
                <h3 style={{ margin: "0px", fontWeight: "400" }}>
                  {" " + intToDollars(quarterTotal)}
                </h3>
              </div>
            </div>
          </div>
          <div style={mainPanel}>
            <div>
              <div
                style={{
                  margin: "auto",
                  minHeight: "100vh",
                  height: "100%",
                  paddingBottom: "10px",
                }}
              >
                <div id="Filters Bar">
                  <div>
                    <div
                      style={{
                        padding: "20px",
                        paddingTop: "50px",
                      }}
                    >
                      <button
                        style={{ maxWidth: "150px" }}
                        onClick={() => {
                          setDisplayFilters(!displayFilters);
                        }}
                      >
                        {displayFilters ? "Hide Filters" : "Show Filters"}
                      </button>
                    </div>
                  </div>
                  {displayFilters ? (
                    <div
                      className="filtersBar scrollable-element"
                      id="filtersBar"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "30px",
                        alignItems: "center",
                        color: "black",
                        scrollbarColor: "dark",
                      }}
                    >
                      <div
                        className="filter"
                        style={{
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ margin: "0px" }}>Status</p>

                        <select
                          className="filter"
                          defaultValue=""
                          style={{
                            minWidth: "150px",
                            color: "black",
                            borderRadius: "5px",
                            height: "50px",
                            maxHeight: "50px",
                            border: "none",
                            boxShadow: "0px 0px 4px #afafaf",
                            fontSize: "18px",
                            fontWeight: "600",
                            fontFamily: "lato",
                            padding: "10px",
                          }}
                          onChange={(e) => {
                            if (e.target.value === "Show All") {
                              setRequests((prevState) => {
                                const state = { ...prevState };
                                state["shippingStatus"] = undefined;
                                return state;
                              });
                            } else {
                              setRequests((prevState) => ({
                                ...prevState,
                                shippingStatus: e.target.value,
                              }));
                            }
                          }}
                        >
                          <option value="Show All">Show All</option>
                          <option value="Pending">Pending</option>
                          <option value="Shipped">Shipped</option>
                        </select>
                      </div>
                      <div
                        className="filter"
                        style={{
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ margin: "0px" }}>Start Date</p>
                        <input
                          type="date"
                          className="filter"
                          style={{
                            marginRight: "0px",
                            minWidth: "150px",
                            color: "black",
                            borderRadius: "5px",
                            height: "50px",
                            maxHeight: "50px",
                            border: "none",
                            boxShadow: "0px 0px 4px #afafaf",
                            fontSize: "18px",
                            fontWeight: "600",
                            fontFamily: "lato",
                            padding: "10px",
                          }}
                          onChange={(e) => {
                            setRequests((prevState) => ({
                              ...prevState,
                              date: {
                                ...prevState.date,
                                $gte: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div
                        className="filter"
                        style={{
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ margin: "0px" }}>End Date</p>
                        <input
                          className="filter"
                          type="date"
                          style={{
                            marginRight: "0px",
                            color: "black",
                            minWidth: "150px",
                            borderRadius: "5px",
                            height: "50px",
                            maxHeight: "50px",
                            border: "none",
                            boxShadow: "0px 0px 4px #afafaf",
                            fontSize: "18px",
                            fontWeight: "600",
                            fontFamily: "lato",
                            padding: "10px",
                          }}
                          onChange={(e) => {
                            setRequests((prevState) => ({
                              ...prevState,
                              date: {
                                ...prevState.date,
                                $lte: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div style={{ position: "relative" }}>
                        <input
                          placeholder={"Search... "}
                          value={search}
                          onChange={(e) => {
                            setShowSuggestions(true);
                            setSearch(e.target.value);
                            setSearchField(e.target.value);
                          }}
                        />
                        <div
                          style={{
                            display: showSuggestions ? "block" : "none",
                            zIndex: "99",
                            position: "absolute",
                          }}
                        >
                          <ol>
                            {suggestions.map((suggestion, index) => {
                              return (
                                <li
                                  onClick={() => {
                                    setShowSuggestions(false);
                                    console.log(suggestion.company);
                                    setSearch(suggestion.company);
                                    setRequests((prevState) => ({
                                      ...prevState,
                                      company: suggestion.company,
                                    }));
                                  }}
                                  key={index}
                                >
                                  {suggestion.company}
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>

                      <button
                        className="filterButton"
                        style={{
                          borderRadius: "5px",
                          height: "100%",
                          color: "black",
                        }}
                        onClick={() => {
                          filter(requests);
                        }}
                      >
                        Search
                      </button>

                      <button
                        className="filterButton"
                        style={{
                          borderRadius: "5px",
                          height: "100%",
                          color: "black",
                        }}
                        onClick={() => {
                          document.location.reload();
                        }}
                      >
                        Clear Filters
                      </button>

                      {allPurchases.length > 0 ? (
                        <button
                          className="filterButton"
                          style={{ borderRadius: "5px" }}
                          onClick={() => {
                            exportFile(allPurchases);
                          }}
                        >
                          Export Excel
                        </button>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                {loading === true ? (
                  <div>
                    <div
                      style={{
                        padding: "10px",
                        height: "100%",
                      }}
                    >
                      <LoadAdminTiles />
                      <LoadAdminTiles />
                      <LoadAdminTiles />
                      <LoadAdminTiles />
                      <LoadAdminTiles />
                      <LoadAdminTiles />
                      <LoadAdminTiles />
                      <LoadAdminTiles />
                      <LoadAdminTiles />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      paddingTop: "10px",
                      padding: "10px",
                      minHeight: "100vh",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      gap: "30px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div>
                      <h4 style={{ margin: "0px" }}>{count} Purchases Found</h4>
                    </div>
                    <div>
                      <Items currentItems={currentItems} />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          className="pagination"
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          renderOnZeroPageCount={null}
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
