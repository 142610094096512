import "../css/hamburger.css";

export default function HamburgerMenu() {
  async function menuClick() {
    console.log("click");
    const hamburger = document.querySelector(".hamburger");
    const nav_menu = document.querySelector(".nav-menu");

    hamburger.classList.toggle("active");
    nav_menu.classList.toggle("active");

    document.querySelectorAll(".nav-link").forEach((n) =>
      n.addEventListener("click", () => {
        hamburger.classList.remove("active");
        nav_menu.classList.remove("active");
      })
    );
  }

  return (
    <nav className="navbar">
      <ul className="nav-menu">
        <li className="nav-item">
          <a
            href="/userDashboard"
            aria-current="page"
            className="nav-link w--current"
          >
            Home
          </a>
        </li>
        <li className="nav-item">
          <a href="/benefits" className="nav-link">
            Beneifts
          </a>
        </li>

        <li className="nav-item">
          <a href="/contact" className="nav-link">
            Contact Us
          </a>
        </li>
      </ul>
      <div
        className="hamburger"
        onClick={() => {
          menuClick();
        }}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
}
