import { combineReducers } from "redux";
import cart from "./store";
import Company from "./companyReducer";
import User from "./user";
import Alerts from "./alertsReducer";

export default combineReducers({
  cart,
  Company,
  User,
  Alerts,
});
