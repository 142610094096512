const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  company: "",
  poBox: "",
  purchaserPhone: "",
  billingAddress: "",
  billingCity: "",
  billingState: "",
  billingZipcode: "",
  shippingPhone: "",
  shippingAddress: "",
  shippingCity: "",
  shippingState: "",
  shippingZipcode: "",
  repName: "",
  repPhone: "",
  attn: "",
  tier: "",
  date: new Date(),
  office: "",
};

export default function Company(state = initialState, action) {
  switch (action.type) {
    case "selectCompany":
      return action.data;
    case "clearCompany":
      return initialState;
    default:
      return state;
  }
}
