import { useShoppingCart } from "../Context/shoppingCartContext";
import AllProducts from "../json/products.json";

export function CartItem({ id, quantity }) {
  const { removeFromCart, getItemQuantity } = useShoppingCart();

  const item = AllProducts.find((i) => i._id == id);
  if (item == null) return null;

  return (
    <div className="slide-in-cart-product">
      <a href={"/phyiscalProduct/" + id} className="link-block w-inline-block">
        {/* <img
          src={"/images/" + item.productImage}
          className="slide-in-cart-product-image"
          style={{ objectFit: "contain" }}
        /> */}
        <div className="slide-in-cart-product-quantity">
          {" "}
          x{getItemQuantity(item.name)}
        </div>
      </a>

      <div className="slide-in-cart-product-details-container">
        <div className="slide-in-cart-product-title-container">
          <div
            className="slide-in-cart-prroduct-title"
            style={{ fontSize: 16 }}
          >
            {item.title}
          </div>
          <div className="slide-in-cart-product-quantity">
            x{getItemQuantity(item.name)}
          </div>
        </div>
        <div className="slide-in-cart-product-price">
          {/* {item.price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })} */}
        </div>
      </div>
      <div className="slide-in-cart-product-total">
        {/* {(item.price * quantity).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })} */}
      </div>
      <button
        onClick={() => {
          removeFromCart(id);
        }}
        className="slide-in-cart-product-remove-button"
      >
        X
      </button>
    </div>
  );
}
