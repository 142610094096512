import React, { useEffect, useState } from "react";
import Header from "../Header";
import axios from "axios";
import UserRow from "./components/userRow";
import Modal from "react-modal";
import "./styles.css";
import "./paginationStyles.css";
import ReactPaginate from "react-paginate";
import DashboardNav from "../Dashboard/dashboardNav";

export default function Users() {
  var fileDownload = require("js-file-download");

  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    active: true,
    type: "rep"
  });
  const [status, setStatus] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [search, setSearch] = useState("");

  const mainPanel = {
    paddingBottom: "60px",
    width: "100%",
    backgroundColor: "white",
    overflowY: "scroll"
  };

  useEffect(() => {
    axios.get("/api/users/getUsers").then(res => {
      setUsersData(res.data, setLoading(false));
    });
  }, []);

  useEffect(() => {
    setCurrentItems(
      usersData.filter(item => {
        if (search === "") {
          return item;
        } else if (
          item.firstName
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        ) {
          return item;
        } else return null;
      })
    );
  }, [search]);

  useEffect(() => {
    setLoading(true);
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(usersData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(usersData.length / itemsPerPage), setLoading(false));
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [itemOffset, itemsPerPage, usersData]);

  useEffect(() => {
    axios.get("/api/users/getUsers").then(res => {
      setUsersData(res.data);
    });
  }, [status]);

  const resetStatus = () => {
    setLoading(true);
    setStatus(!status);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };

  Modal.setAppElement("#root");

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  function Submit(e) {
    //e.preventDefault();
    console.log(formData);
    axios.post("/api/users/newUser", formData).then(res => {
      if (res.status === 200) {
        window.location.reload();
      }
    });
  }

  const inputUpdate = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  function Items({ currentItems }) {
    return currentItems.length > 0 ? (
      currentItems.map((user, index) => {
        return (
          <UserRow
            key={index}
            props={{ user: user, index: index }}
            resetStatus={resetStatus}
            style={{ overflow: "hidden" }}
          />
        );
      })
    ) : (
      <div></div>
    );
  }

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % usersData.length;
    setItemOffset(newOffset);
  };

  const userExport = () => {
    axios
      .post(
        "/api/export/usersDataForImport",
        { data: usersData },
        { responseType: "blob" }
      )
      .then(response => {
        fileDownload(response.data, `excel${Date.now()}.xlsx`);
      });
  };

  return (
    <div style={{ maxHeight: "100vh", overflowY: "hidden" }}>
      <Header />
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          height: "100vh"
        }}
      >
        <DashboardNav />
        <div style={mainPanel}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "30px",
              padding: "0px 30px"
            }}
          >
            <div>
              {" "}
              <h1>{usersData.length} Users</h1>
              <select onChange={e => setItemsPerPage(Number(e.target.value))}>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={150}>150</option>
                <option value={200}>200</option>
                <option value={500}>500</option>
              </select>
            </div>
            <input
              placeholder="Search... "
              onChange={e => {
                setSearch(e.target.value);
              }}
            />

            <button style={{ width: "150px" }} onClick={openModal}>
              Add User
            </button>
            <button
              className="filterButton"
              style={{ borderRadius: "5px" }}
              onClick={() => {
                userExport();
              }}
            >
              Export Excel
            </button>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              textAlign: "left",
              padding: "10px 30px",
              borderBottom: "2px solid black"
            }}
          >
            <div
              style={{
                textAlign: "left",
                width: "100%"
              }}
            >
              <h4>First Name</h4>
            </div>
            <div
              style={{
                textAlign: "left",
                width: "100%"
              }}
            >
              <h4>Last Name</h4>
            </div>
            <div
              style={{
                textAlign: "left",
                width: "100%"
              }}
            >
              <h4>Email</h4>
            </div>
            <div
              style={{
                textAlign: "left",
                width: "100%"
              }}
            >
              <h4>Phone Number</h4>
            </div>
            <div
              style={{
                textAlign: "left",
                width: "100%"
              }}
            >
              <h4>Company</h4>
            </div>
            <div
              style={{
                textAlign: "left",
                width: "100%"
              }}
            >
              <h4>Actions</h4>
            </div>
          </div>
          {loading === false ? (
            <div>
              <Items currentItems={currentItems} />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <ReactPaginate
                  className="pagination"
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  activeClassName="active"
                />
              </div>
            </div>
          ) : (
            <div className="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add User"
            overlayClassName="Overlay"
          >
            <h2 ref={_subtitle => (subtitle = _subtitle)}>Add User</h2>
            <form
              onSubmit={e => {
                Submit(e);
              }}
            >
              <input
                required
                name="firstName"
                className="newUserField"
                placeholder="First Name"
                onChange={e => {
                  inputUpdate(e);
                }}
              />
              <input
                required
                name="lastname"
                className="newUserField"
                placeholder="Last Name"
                onChange={e => {
                  inputUpdate(e);
                }}
              />
              <input
                required
                name="TBMPhoneNumber"
                className="newUserField"
                placeholder="Phone Number"
                onChange={e => {
                  inputUpdate(e);
                }}
              />
              <input
                required
                name="TBMEmail"
                className="newUserField"
                placeholder="Email"
                onChange={e => {
                  inputUpdate(e);
                }}
              />
              <input
                required
                name="company"
                className="newUserField"
                placeholder="Company"
                onChange={e => {
                  inputUpdate(e);
                }}
              />
              <select
                required
                name="type"
                className="newUserField"
                onChange={e => {
                  inputUpdate(e);
                }}
              >
                <option value="rep">Rep</option>
                <option value="Admin">Admin</option>
              </select>
              <div
                style={{
                  display: "flex",
                  gap: "10px"
                }}
              >
                <button className="userSubmitButton" type="submit">
                  Submit
                </button>
                <button className="userCloseButton" onClick={closeModal}>
                  close
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    </div>
  );
}
