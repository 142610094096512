import React from "react";

export default function DownloadSpreadsheet() {
  const queryString = window.location.pathname.split("/")[2];
  console.log(queryString);

  const link = document.createElement("a");
  link.href = `/semester_totals/${queryString}.xlsx`;
  link.setAttribute("download", `${queryString}.xlsx`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);

  document.getElementById("link");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
        <img src={require("../../Images/downloading.png")} />
        <h1>Downloading Account Data</h1>
      </div>
    </div>
  );
}
