import React from "react";

export default function Benefit(props) {
  return (
    <div
      style={{
        maxWidth: "900px",
        height: "300px",
        margin: "auto",
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        gap: 0,
        borderBottom: "1px solid black",
      }}
    >
      <div
        style={{
          width: "33%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <img
          src={props.img}
          style={{ margin: "auto" }}
          width="50%"
          height={"50%"}
        />
      </div>
      <div style={{ width: "66%" }}>
        <h3>{props.title}</h3>
        <p>{props.paragraph}</p>
      </div>
    </div>
  );
}
