import React from "react";
import { useState, useEffect } from "react";
import "../App/App.css";
import Header from "../Header";
import { intToDollars } from "../Functions/intConversions";

export default function Training() {
  const [finalTotal, setFinalTotal] = useState(0);
  const [injectors, setInjectors] = useState(1);
  const injectorsInputs = [];
  const [openNext, setOpenNext] = useState(false);
  const childrenHyperDilute = [];
  const childrenRadiesse = [];
  const childrenRadiesseBelotero = [];
  const [
    numHyperDiluteRadiesseChildren,
    setNumHyperDiluteRadiesseChildren
  ] = useState(0);
  const [numRadiesseChildren, setNumRadiesseChildren] = useState(0);
  const [
    numRadiesseBeloteroChildren,
    setNumRadiesseBeloteroChildren
  ] = useState(0);

  for (var i = 0; i < injectors; i += 1) {
    injectorsInputs.push(
      <input
        key={i}
        placeholder={"Florida Medical Lic. #"}
        style={{
          width: "100%",
          fontSize: "22px",
          marginBottom: "10px",
          padding: "10px"
        }}
      />
    );
  }

  for (var j = 0; j < numHyperDiluteRadiesseChildren; j += 1) {
    childrenHyperDilute.push(
      <select
        key={j}
        className="selectionList"
        id={`hyperdilute_` + j + `_selection`}
        onChange={e => {
          setTotals({
            ...totals,
            [e.target.id]: parseInt(e.target.value, 10)
          });
        }}
      >
        <option value={0}>None</option>
        <option value={750}>Face +$750</option>
        <option value={750}>Neck +$750</option>
        <option value={750}>Decollote +$750</option>
        <option value={750}>Arms +$750</option>
        <option value={750}>Knees +$750</option>
        <option value={750}>Hands +$750</option>
      </select>
    );
  }

  for (var k = 0; k < numRadiesseChildren; k += 1) {
    childrenRadiesse.push(
      <select
        key={k}
        className="selectionList"
        id={`radiesse_` + k + `_selection`}
        onChange={e => {
          setTotals({
            ...totals,
            [e.target.id]: parseInt(e.target.value, 10)
          });
        }}
      >
        <option value={0}>None</option>
        <option value={500}>Cheeks +$500</option>
        <option value={500}>N/L Folds +$500</option>
        <option value={500}>Marionettes +$500</option>
        <option value={500}>Jawline +$500</option>
        <option value={500}>Pre-Jowl Sulcus +$500</option>
        <option value={500}>Chin +$500</option>
      </select>
    );
  }

  for (var l = 0; l < numRadiesseBeloteroChildren; l += 1) {
    childrenRadiesseBelotero.push(
      <select
        key={k}
        className="selectionList"
        id={`radiesse_` + l + `_selection`}
        onChange={e => {
          setTotals({
            ...totals,
            [e.target.id]: parseInt(e.target.value, 10)
          });
        }}
      >
        <option value={0}>None</option>
        <option value={500}>Cheeks +$500</option>
        <option value={500}>N/L Folds +$500</option>
        <option value={500}>Marionettes +$500</option>
        <option value={500}>Jawline +$500</option>
        <option value={500}>Pre-Jowl Sulcus +$500</option>
        <option value={500}>Chin +$500</option>
      </select>
    );
  }

  //Check box logic
  //////////////////////////////////////
  const [totals, setTotals] = useState({});
  useEffect(() => {
    let addingPrice = 0;

    Object.keys(totals).forEach(eachClass => {
      addingPrice = addingPrice + totals[eachClass];
    });

    setFinalTotal(addingPrice);
  }, [totals]);

  const addHyperdiluteRadiesseArea = () => {
    setNumHyperDiluteRadiesseChildren(prevState => prevState + 1);
  };

  const addRadiesseArea = () => {
    setNumRadiesseChildren(prevState => prevState + 1);
  };

  const addRadiesseBeloteroArea = () => {
    setNumRadiesseBeloteroChildren(prevState => prevState + 1);
  };

  return (
    <>
      <Header />
      <div className="texture">
        <h1 style={{ color: "white", maxWidth: "900px", margin: "auto" }}>
          Training
        </h1>
        {/* Xeomin 
        /////////////////////////////////////////////////*/}
        <div className="trainingContainer">
          <div className="trainingHeader">
            <input
              placeholder={0}
              type="checkbox"
              id="xeomin"
              className="checkboxScale"
              onClick={e => {
                e.target.checked
                  ? setTotals({
                      ...totals,
                      [e.target.id]: 800
                    })
                  : setTotals({
                      ...totals,
                      [e.target.id]: 0
                    });
              }}
            />
            <h3 className="className">Xeomin $800</h3>
          </div>
          <div>
            <ul style={{ color: "white" }}>
              <li key="1">
                <p>
                  Best business practices of Xeomin and how to educate a patient
                  on the difference
                </p>
              </li>
              <li key="2">
                <p>Train on how to perform our signature brow lift</p>
              </li>
            </ul>
          </div>
        </div>
        {/* Belotero 
        /////////////////////////////////////////////////*/}
        <div className="trainingContainer">
          <div className="trainingHeader">
            <input
              placeholder="0"
              type="checkbox"
              className="checkboxScale"
              id="belotero"
              onClick={e => {
                e.target.checked
                  ? setTotals({
                      ...totals,
                      [e.target.id]: 2500
                    })
                  : setTotals({
                      ...totals,
                      [e.target.id]: 0
                    });
              }}
            />
            <h3 className="className">Belotero $2500</h3>
          </div>
          <div>
            <ul style={{ color: "white" }}>
              <li>
                <p>
                  Education on product and best business practices for Belotero
                </p>
              </li>
              <li>
                <p>Overview of Anatomy</p>
              </li>
              <li>
                <p>Train on Tear Troughs and wrinkle lines</p>
              </li>
            </ul>
          </div>
        </div>
        {/* Radiesse 
        /////////////////////////////////////////////////*/}
        <div className="trainingContainer">
          <div className="trainingHeader">
            <input
              type="checkbox"
              className="checkboxScale"
              id="radiesse"
              onClick={e => {
                e.target.checked
                  ? setTotals({
                      ...totals,
                      [e.target.id]: 3000
                    })
                  : setTotals({
                      ...totals,
                      [e.target.id]: 0
                    });
              }}
            />
            <h3 className="className">Radiesse $3000</h3>
          </div>
          <div>
            <ul style={{ color: "white" }}>
              <li>
                <p>
                  Education on the best business practices for Radiesse+ and how
                  to pick the right pataient.
                </p>
              </li>
              <li>
                <p>Overview of Anatomy</p>
              </li>
              <li>
                <p>
                  We will review all of the areas that Radiesse+ can be used
                </p>
              </li>
              <li>
                <p>
                  Pick two of the following areas to treat, additional areas are
                  +$500 each
                </p>
              </li>
            </ul>
          </div>
          {totals.radiesse === 3000 && numRadiesseChildren < 4 ? (
            <>
              <select
                className="selectionList"
                id="Radiesse_first_selection"
                onChange={e => {
                  setTotals({
                    ...totals,
                    [e.target.id]: parseInt(e.target.value, 10)
                  });
                }}
              >
                <option value={0}>Cheeks</option>
                <option value={0}>N/L Folds</option>
                <option value={0}>Marionettes</option>
                <option value={0}>Jawline</option>
                <option value={0}>Pre-Jowl Sulcus</option>
                <option value={0}>Chin</option>
              </select>
              <select
                className="selectionList"
                id="Radiesse_second_selection"
                onChange={e => {
                  setTotals({
                    ...totals,
                    [e.target.id]: parseInt(e.target.value, 10)
                  });
                }}
              >
                <option value={0}>Cheeks</option>
                <option value={0}>N/L Folds</option>
                <option value={0}>Marionettes</option>
                <option value={0}>Jawline</option>
                <option value={0}>Pre-Jowl Sulcus</option>
                <option value={0}>Chin</option>
              </select>

              <div id="additional_hyperdilute">{childrenRadiesse}</div>

              <button
                style={{
                  marginTop: "20px",
                  backgroundColor: "#303030",
                  borderRadius: "5px",
                  color: "white",
                  boxShadow: "2px 2px 4px black, -2px -2px 4px grey"
                }}
                onClick={() => addRadiesseArea()}
              >
                + Add Additional Area
              </button>
            </>
          ) : (
            <> </>
          )}
        </div>
        {/* Radiesse + Belo 
        /////////////////////////////////////////////////*/}
        <div className="trainingContainer">
          <div className="trainingHeader">
            <input
              placeholder="0"
              type="checkbox"
              className="checkboxScale"
              id="radiesseBelotero"
              onClick={e => {
                e.target.checked
                  ? setTotals({
                      ...totals,
                      [e.target.id]: 4500
                    })
                  : setTotals({
                      ...totals,
                      [e.target.id]: 0
                    });
              }}
            />
            <h3 className="className">Radiesse + Belotero $4500</h3>
          </div>
          <div>
            <ul style={{ color: "white" }}>
              <li>
                <p>Combination of all of the above</p>
              </li>
            </ul>
          </div>
          {totals.radiesseBelotero === 4500 &&
          numRadiesseBeloteroChildren < 4 ? (
            <>
              <select
                className="selectionList"
                id="hyperdilute_first_selection"
                onChange={e => {
                  setTotals({
                    ...totals,
                    [e.target.id]: parseInt(e.target.value, 10)
                  });
                }}
              >
                <option value={0}>Cheeks</option>
                <option value={0}>N/L Folds</option>
                <option value={0}>Marionettes</option>
                <option value={0}>Jawline</option>
                <option value={0}>Pre-Jowl Sulcus</option>
                <option value={0}>Chin</option>
              </select>
              <select
                className="selectionList"
                id="hyperdilute_second_selection"
                onChange={e => {
                  setTotals({
                    ...totals,
                    [e.target.id]: parseInt(e.target.value, 10)
                  });
                }}
              >
                <option value={0}>Cheeks</option>
                <option value={0}>N/L Folds</option>
                <option value={0}>Marionettes</option>
                <option value={0}>Jawline</option>
                <option value={0}>Pre-Jowl Sulcus</option>
                <option value={0}>Chin</option>
              </select>
              <div id="additional_hyperdilute">{childrenRadiesseBelotero}</div>

              <button
                style={{
                  marginTop: "20px",
                  backgroundColor: "#303030",
                  borderRadius: "5px",
                  color: "white",
                  boxShadow: "2px 2px 4px black, -2px -2px 4px grey"
                }}
                onClick={() => addRadiesseBeloteroArea()}
              >
                + Add Additional Area
              </button>
            </>
          ) : (
            <> </>
          )}
        </div>
        {/* Hyper Radiesse 
        /////////////////////////////////////////////////*/}
        <div className="trainingContainer">
          <div className="trainingHeader">
            <input
              placeholder="0"
              type="checkbox"
              className="checkboxScale"
              id="hyperdilute"
              onClick={e => {
                e.target.checked
                  ? setTotals({
                      ...totals,
                      [e.target.id]: 5000
                    })
                  : setTotals({
                      ...totals,
                      [e.target.id]: 0
                    });
              }}
            />
            <h3 className="className">Hyperdilute Radiesse $5000</h3>
          </div>
          <div>
            <ul style={{ color: "white" }}>
              <li>
                <p>
                  Education on product and best business practices for
                  Hyperdilute
                </p>
              </li>
              <li>
                <p>
                  We will review all the areas to treat with hyperdilute and
                  review the proper dilutions per each treatment area.
                </p>
                <p>Pick two areas to inject, each additional area is +$750</p>
              </li>
            </ul>
            {totals.hyperdilute === 5000 &&
            numHyperDiluteRadiesseChildren < 4 ? (
              <>
                <select
                  className="selectionList"
                  id="hyperdilute_first_selection"
                  onChange={e => {
                    setTotals({
                      ...totals,
                      [e.target.id]: parseInt(e.target.value, 10)
                    });
                  }}
                >
                  <option value={0}>Face</option>
                  <option value={0}>Neck</option>
                  <option value={0}>Decollote</option>
                  <option value={0}>Arms</option>
                  <option value={0}>Knees</option>
                  <option value={0}>Hands</option>
                </select>
                <select
                  className="selectionList"
                  id="hyperdilute_second_selection"
                  onChange={e => {
                    setTotals({
                      ...totals,
                      [e.target.id]: parseInt(e.target.value, 10)
                    });
                  }}
                >
                  <option value={0}>Face</option>
                  <option value={0}>Neck</option>
                  <option value={0}>Decollote</option>
                  <option value={0}>Arms</option>
                  <option value={0}>Knees</option>
                  <option value={0}>Hands</option>
                </select>
                <div id="additional_hyperdilute">{childrenHyperDilute}</div>

                <button
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#303030",
                    borderRadius: "5px",
                    color: "white",
                    boxShadow: "2px 2px 4px black, -2px -2px 4px grey"
                  }}
                  onClick={() => addHyperdiluteRadiesseArea()}
                >
                  + Add Additional Area
                </button>
              </>
            ) : (
              <> </>
            )}
          </div>
        </div>
        {/* Radiesse Butt 
        /////////////////////////////////////////////////*/}
        <div className="trainingContainer">
          <div className="trainingHeader">
            <input
              placeholder="0"
              type="checkbox"
              className="checkboxScale"
              id="buttocks"
              onClick={e => {
                e.target.checked
                  ? setTotals({
                      ...totals,
                      [e.target.id]: 8000
                    })
                  : setTotals({
                      ...totals,
                      [e.target.id]: 0
                    });
              }}
            />
            <h3 className="className">Hyperdilute Radiesse Buttocks $8000</h3>
          </div>
          <div>
            <ul style={{ color: "white" }}>
              <li>
                <p>
                  Education on best business practices for Hyperdilute and how
                  to pick the right patient
                </p>
              </li>
              <li>
                <p>
                  We will review all the areas to treat with hyperdilute and
                  review the proper dilutions per each treatment area.
                </p>
              </li>
              <li>
                <p>
                  We will then train you on how to perform the injections on one
                  side of the buttocks and then allow you to inject the other
                  side.
                </p>
              </li>
            </ul>
          </div>
        </div>
        {/* Training 
        /////////////////////////////////////////////////*/}
        <div className="trainingContainer">
          <div>
            <h3 className="className" style={{ textAlign: "left" }}>
              Trainees *
            </h3>
          </div>
          <p style={{ color: "white" }}>Max of three trainees</p>
          <select className="selectionList" id="trainees">
            <option value={1}>1 Trainee</option>
            <option value={2}>2 Trainees </option>
            <option value={3}>3 Trainees </option>
          </select>
        </div>
        <div className="trainingContainer">
          <div>
            <h3 className="className" style={{ textAlign: "left" }}>
              Injectors *
            </h3>
          </div>
          <div>
            <p style={{ color: "white" }}>
              One injector is included in the price. For each additional
              injector ( max of three ) there will be an additional $1500
              charge.
            </p>
          </div>
          <select
            className="selectionList"
            id="injectors"
            onChange={e => {
              setTotals({
                ...totals,
                [e.target.id]: parseInt(e.target.value, 10)
              });

              setInjectors(parseInt(e.target.selectedIndex + 1, 10));
            }}
          >
            <option id={1} value={0}>
              1 Injector
            </option>
            <option id={2} value={1500}>
              2 Injectors +$1500
            </option>
            <option id={3} value={3000}>
              3 Injectors +$3000
            </option>
          </select>
        </div>
        <div className="trainingContainer">
          <div className="trainingHeader">
            <h3 className="className">Models *</h3>
          </div>
          <div>
            <div className="treatmentSelections">
              <select
                className="selectionList"
                id="model"
                onChange={e => {
                  setTotals({
                    ...totals,
                    [e.target.id]: parseInt(e.target.value, 10)
                  });
                }}
              >
                <option value={0}>Trainee provides model</option>
                <option value={1000}>We provide model +$1000</option>
              </select>
            </div>
          </div>
        </div>
        <div className="trainingContainer">
          <div className="trainingHeader">
            <h3 className="className">Trainee Info *</h3>
          </div>
          <div>
            <p style={{ color: "white" }}>
              Please let us know a date range you are interested in. As well as
              the license numbers of all the injectors.
            </p>
            <input
              placeholder="Name"
              style={{
                width: "100%",
                fontSize: "22px",
                marginBottom: "10px",
                padding: "10px"
              }}
            />
            <input
              placeholder="Address"
              style={{
                width: "100%",
                fontSize: "22px",
                marginBottom: "10px",
                padding: "10px"
              }}
            />
            <input
              placeholder="State"
              style={{
                width: "100%",
                fontSize: "22px",
                marginBottom: "10px",
                padding: "10px"
              }}
            />
            <input
              placeholder="Zipcode"
              style={{
                width: "100%",
                fontSize: "22px",
                marginBottom: "10px",
                padding: "10px"
              }}
            />
            {injectorsInputs}
            <textarea
              placeholder="Date Range - Ex. January"
              style={{
                width: "100%",
                fontSize: "22px",
                marginBottom: "10px",
                padding: "10px",
                height: "100%"
              }}
            />
          </div>
        </div>
        <div
          style={{ maxWidth: "900px", margin: "auto", paddingBottom: "60px" }}
        >
          <div className="trainingHeader" style={{ marginTop: "60px" }}>
            <div>
              <h1 style={{ color: "white" }}>
                Total: ${intToDollars(finalTotal)}
              </h1>
            </div>
          </div>
          {openNext !== true ? (
            <p style={{ color: "white" }}>
              PLEASE BE AWARE... IF INJECTING, YOU MUST POSSESS A MEDIAL LICENSE
              NUMBER IN THE STATE OF FLORIDA
            </p>
          ) : (
            <> </>
          )}
          <div className="orderButtonContainer">
            {openNext !== true ? (
              <button
                style={{
                  marginTop: "20px",
                  backgroundColor: "#303030",
                  borderRadius: "5px",
                  color: "white",
                  boxShadow: "2px 2px 4px black, -2px -2px 4px grey"
                }}
                onClick={() => {
                  setOpenNext(prevState => !prevState);
                }}
              >
                I Agree
              </button>
            ) : (
              <button type="submit">Send Training Request</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
