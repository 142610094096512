const initialState = {
  cart: [],
  totalCost: 0,
  tier: "tierThreePrice",
  minimumPrice: 20000,
  emailSuccessful: "",
  cardProccessed: "",
};

const cart = (state = initialState, action) => {
  switch (action.type) {
    case "purchase":
      return {
        type: "added to cart",
        cart: action.cart,
        totalCost: action.totalCost,
        tier: action.tier,
        minimumPrice: action.minimumPrice,
      };
    case "update":
      return {
        ...state,
        minimumPrice: action.minimumPrice,
      };
    case "tierChange":
      return {
        ...state,
        tier: action.tier,
      };
    case "successfulPurchase":
      return {
        ...state,
        cardProccessed: action.cardReturn,
      };
    case "emailSuccessful":
      return {
        ...state,
        emailSuccessful: action.emailReturn,
      };
    case "reset":
      return initialState;
    default:
      return state;
  }
};

export default cart;
