import React, { useState } from "react";
import axios from "axios";

export default function AdminInputPurchase() {
  ////////////////////////////////////
  //  set the ordering details
  ////////////////////////////////////
  const [details, setDetails] = useState({
    poBox: "N/A",
    purchaserPhone: "N/A",
    billingAddress: "N/A",
    billingCity: "N/A",
    billingState: "N/A",
    billingZipcode: "N/A",
    shippingPhone: "N/A",
    shippingAddress: "N/A",
    shippingCity: "N/A",
    shippingState: "N/A",
    shippingZipcode: "N/A",
    repName: "N/A",
    repPhone: "N/A",
    attn: "N/A",
    tier: "N/A",
  });

  const addPurchase = (e) => {
    console.log(details);
    e.preventDefault();
    axios
      .post("/api/orders/adminPurchase", { details: details })
      .then((res) => {
        console.log(res);
        document.location.reload();
      });
  };

  return (
    <div>
      <form>
        <div style={{ maxWidth: "900px", margin: "auto" }}>
          <div>
            <h3>Date String Example: 2021-11-01T15:44:49.933+00:00</h3>
            <input
              className="orderInputFields"
              placeholder="Amount"
              type="number"
              required
              id="amount"
              onChange={(e) => {
                setDetails({
                  ...details,
                  [e.target.id]: Number(e.target.value),
                });
              }}
            />

            <input
              className="orderInputFields"
              placeholder="First Name"
              type="text"
              required
              id="first_name"
              onChange={(e) => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value,
                });
              }}
            />
            <input
              className="orderInputFields"
              placeholder={"Last Name"}
              type="text"
              required
              id="last_name"
              onChange={(e) => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value,
                });
              }}
            />

            <input
              className="orderInputFields"
              placeholder={"Date"}
              type="text"
              required
              id="date"
              onChange={(e) => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value,
                });
              }}
            />

            <input
              className="orderInputFields"
              placeholder={"Company ID"}
              type="text"
              required
              id="_id"
              onChange={(e) => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value,
                });
              }}
            />

            <input
              className="orderInputFields"
              placeholder={"Company NAME"}
              type="text"
              required
              id="company"
              onChange={(e) => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value,
                });
              }}
            />

            <button onClick={(e) => addPurchase(e)}>Purchase</button>
          </div>
        </div>
      </form>
    </div>
  );
}
