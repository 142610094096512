import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import PURCHASES from "../../Images/purchases.png";
import ADDUSER from "../../Images/add-user.png";
import ACCOUNTS from "../../Images/accounts.png";
import TESTING from "../../Images/testing.png";
import PRODUCTS from "../../Images/products.png";
import PLUS from "../../Images/plus.png";
import PLUS_SELECTED from "../../Images/plusSelected.png";
import LOGO from "../../Images/Side-by-Side-white.png";
import DASHBOARD from "../../Images/dashboard.png";
import DASHBOARD_SELECTED from "../../Images/dashboardSelected.png";
import Contact from "../../Images/contact.png";
import FIELDGUIDE from "../../Images/fieldGuide.png";
import SCHEDULE from "../../Images/schedule.png";
import BENEFITS from "../../Images/benefits.png";

import UseAuth from "../App/auth";

// import { Container } from './styles';

function Dashboard() {
  const checkActive = (page) => {
    let location = window.location.pathname;
    if (!location) return false;
    return location === page ? true : false;
  };

  const User = localStorage.getItem("userType");

  const { logout } = UseAuth();

  return (
    <div
      className="dashboardNavigation"
      style={{ backgroundColor: "#15181f", padding: "0px 0px 10px 0px" }}
    >
      <div className="sideBarAdminTools" id="sidebar">
        <div style={{ padding: "10px" }}>
          <img alt="Logo" src={LOGO} style={{ width: "75%", height: "100%" }} />
        </div>
        {User === "true" ? (
          <>
            <NavLink
              to={"/adminDashboard"}
              className={
                checkActive("/adminDashboard") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Admin Center"
                title="Admin Center"
                src={DASHBOARD}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Dashboard
            </NavLink>
            <NavLink
              to="/allpurchases"
              className={
                checkActive("/allpurchases") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Admin Center"
                title="Admin Center"
                src={PURCHASES}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Purchases
            </NavLink>

            <NavLink
              to="/scheduleManager"
              className={
                checkActive("/scheduleManager") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Field Guide"
                src={SCHEDULE}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Events
            </NavLink>

            <NavLink
              to="/users"
              className={
                checkActive("/users") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Users"
                title="Users"
                src={ADDUSER}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Users
            </NavLink>
            <NavLink
              to="/accounts"
              className={
                checkActive("/accounts") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Accounts"
                title="Accounts"
                src={ACCOUNTS}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Accounts
            </NavLink>
            <NavLink
              to="/viewProducts"
              className={
                checkActive("/viewProducts") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Insert Purchase into Chart"
                title="Insert Purchase into Chart"
                src={PRODUCTS}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Products
            </NavLink>
            <NavLink
              to="/test"
              className={
                checkActive("/test") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Insert Purchase into Chart"
                title="Insert Purchase into Chart"
                src={TESTING}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Testing
            </NavLink>
            <NavLink
              to="/"
              className={
                checkActive("/adminInputPurchase") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
              id="logout"
              onClick={() => {
                localStorage.removeItem("userId");
                localStorage.removeItem("userType");
                localStorage.removeItem("authToken");

                logout();
              }}
            >
              Logout
            </NavLink>
          </>
        ) : (
          <>
            <NavLink
              to={"/userDashboard"}
              className={
                checkActive("/userDashboard") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Admin Center"
                title="Admin Center"
                src={
                  checkActive("/userDashboard") === true
                    ? DASHBOARD_SELECTED
                    : DASHBOARD
                }
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Dashboard
            </NavLink>

            <NavLink
              to="/newCompany"
              className={
                checkActive("/newCompany") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="add new account"
                src={checkActive("/newCompany") === true ? PLUS_SELECTED : PLUS}
                style={{ width: "20px", height: "20px" }}
              />
              Add Account
            </NavLink>

            <NavLink
              to="/benefits"
              className={
                checkActive("/benefits") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Benefits"
                src={BENEFITS}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Benefits
            </NavLink>

            <NavLink
              to="/schedule"
              className={
                checkActive("/schedule") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Field Guide"
                src={SCHEDULE}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Event Schedule
            </NavLink>

            <NavLink
              to="/fieldGuide"
              className={
                checkActive("/contact") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Field Guide"
                src={FIELDGUIDE}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Field Guide
            </NavLink>

            <NavLink
              to="/contact"
              className={
                checkActive("/contact") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
            >
              <img
                alt="Contact Us"
                src={Contact}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              Contact Us
            </NavLink>

            <NavLink
              to="/"
              className={
                checkActive("/adminInputPurchase") === true
                  ? "dashboardNavigationItemSelected"
                  : "dashboardNavigationItem"
              }
              id="logout"
              onClick={() => {
                localStorage.removeItem("userId");
                localStorage.removeItem("userType");
                localStorage.removeItem("authToken");

                logout();
              }}
            >
              Logout
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
