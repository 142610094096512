import React from "react";
import NavBar from "../components/navbar";

function FieldGuide() {
  return (
    <div style={{ maxHeight: "100vh", overflowY: "hidden" }}>
      <NavBar />
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
        }}
      >
        <div
          className="dashboardMainPanel"
          id="adminPanel"
          style={{ overflowY: "scroll" }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "5px",
              margin: "0px",
            }}
          >
            <p style={{ fontWeight: "800", paddingLeft: "10px" }}>
              /Field Guide
            </p>
          </div>
          <object
            data={require("../../Images/fieldGuide.pdf")}
            type="application/pdf"
            width="100%"
            height="100%"
          >
            Field Guide
          </object>
        </div>
      </div>
    </div>
  );
}

export default FieldGuide;
