import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import checkMark from "../../Images/ok-256.png";
import ALERT from "../../Images/alertRed.png";
import DashboardNav from "../Dashboard/dashboardNav";

export default function Complete() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const missingInfo = useSelector(state => state.Alerts);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const rowStyles = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    margin: "auto"
  };

  const continueButton = {
    color: "white",
    fontSize: "18px",
    borderRadius: "5px",
    maxWidth: "400px",
    margin: "auto",
    backgroundColor: "#4B95F2"
  };

  return (
    <div style={{ maxHeight: "100vh", overflowY: "hidden" }}>
      <Header style={{ height: "100%", position: "relative" }} />
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          height: "100vh"
        }}
      >
        <DashboardNav />

        <div className="dashboardMainPanel" id="adminPanel">
          <div
            style={{
              backgroundColor: "white",
              padding: "5px",
              margin: "0px"
            }}
          >
            <p style={{ fontWeight: "800", paddingLeft: "10px" }}>/Completed</p>
          </div>
          <div className="" style={{ height: "100%" }}>
            <div
              style={{
                margin: "auto",
                textAlign: "center",
                maxWidth: "1200px"
              }}
            >
              <h1 style={{ fontWeight: "800", fontSize: "48px" }}>
                Thank you for ordering through MedSpa Network!
              </h1>
              <div style={rowStyles}>
                <img
                  alt="checkmark"
                  src={checkMark}
                  style={{
                    maxWidth: "20px",
                    maxHeight: "20px",
                    margin: "10px"
                  }}
                />
                <h4>The card was proccessed successfully</h4>
              </div>
              <div style={rowStyles}>
                <img
                  alt="checkmark"
                  src={checkMark}
                  style={{
                    maxWidth: "20px",
                    maxHeight: "20px",
                    margin: "10px"
                  }}
                />
                <h4>The emails were sent successfully</h4>
              </div>

              {missingInfo.missingInfo === true ? (
                <div style={rowStyles}>
                  <img
                    alt="alert"
                    src={ALERT}
                    style={{
                      maxWidth: "30px",
                      maxHeight: "30px",
                      margin: "10px"
                    }}
                  />
                  <h4 style={{ color: "white" }}>
                    The account that just purchased is missing important
                    information. Please click the following button to add in the
                    rest of the information.
                  </h4>
                </div>
              ) : null}

              <div style={{ marginTop: "60px" }}>
                {missingInfo.missingInfo === true ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px"
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      onClick={() => {
                        navigate(`/viewCompany/${missingInfo._id}`);
                      }}
                    >
                      Fix Info
                    </button>
                    <button
                      to="/order"
                      className=""
                      style={continueButton}
                      onClick={() => {
                        dispatch({
                          type: "reset"
                        });

                        navigate("/dashboard");
                      }}
                    >
                      Cool, lets start a new order!
                    </button>
                  </div>
                ) : (
                  <button
                    to="/order"
                    className=""
                    style={continueButton}
                    onClick={() => {
                      dispatch({
                        type: "reset"
                      });

                      navigate("/dashboard");
                    }}
                  >
                    Cool, lets start a new order!
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
