import { useSelector } from "react-redux";
import moment from "moment";

export default function Flagging(orderTotal) {
  const company = useSelector((state) => state.Company);
  const tier = useSelector((state) => state.cart.tier);
  let thisYear = moment(new Date()).format("YYYY");
  let todaysDate = new Date();
  let minimumPurchasePrice;
  const thisSemester = getSemesterFromMonth(moment(todaysDate).format("M"));
  let PreviousSemester;
  let PreviousYear;
  let tierFromLastSemester;
  let previousSemesterTotal;
  let compareTier;
  let reccomendedTier;
  let openingOrderRecommendedTier;
  let newSemester = false;

  // Set the tier requirements
  switch (tier) {
    case "tierThreePrice":
      minimumPurchasePrice = 5000;
      compareTier = "Elite";
      break;

    case "tierFourPrice":
      minimumPurchasePrice = 10000;
      compareTier = "Elite+";
      break;

    case "tierFivePrice":
      minimumPurchasePrice = 15000;
      compareTier = "VIP";
      break;

    default:
      break;
  }

  ////////////////////////////////////////////////////////
  // Start the calculations for an opening order
  ////////////////////////////////////////////////////////
  function openingOrder(orderTotal) {
    console.log("opening");

    if (orderTotal < 10000) {
      openingOrderRecommendedTier = "Elite";
    } else if (orderTotal < 15000 && orderTotal >= 10000) {
      openingOrderRecommendedTier = "Elite+";
    } else if (orderTotal >= 15000) {
      openingOrderRecommendedTier = "VIP";
    }

    return openingOrderRecommendedTier === compareTier
      ? {
          message: "",
          warn: false,
          openingOrder: true,
        }
      : {
          message:
            "We suggest the " +
            openingOrderRecommendedTier +
            " tier for this order",
          warn: true,
          openingOrder: true,
        };
  }

  ////////////////////////////////////////////////////////
  // Start calculations for a Non opening order
  ////////////////////////////////////////////////////////

  function nonOpeningOrder() {
    console.log("Non opening");
    if (isNewSemester(thisSemester)) {
      return isPurchaseAtCorrectTier();
    } else {
      return calculateReccomendedTierForThisSemester();
    }
  }

  ////////////////////////////////////////////////////////
  // Check if we have started a new semester since the last order
  ///////////////////////////////////////////////////////

  function isNewSemester(thisSemester) {
    let isNew = true;

    for (let i = 0; i < company.orders.length; i++) {
      const purchaseYear = moment(company.orders[i][2]).format("YYYY");
      const purchaseSemester = getSemesterFromMonth(
        moment(company.orders[i][2]).format("M")
      );
      if (
        Number(moment(todaysDate).format("YYYY")) === Number(purchaseYear) &&
        purchaseSemester === thisSemester
      ) {
        isNew = false;
      }
    }

    return isNew;
  }

  ////////////////////////////////////////////////////////
  // Get the semester from the current month
  ///////////////////////////////////////////////////////

  function getSemesterFromMonth(month) {
    let semester1 = [1, 2, 3, 4, 5, 6];

    for (let i = 0; i < semester1.length; i++) {
      if (semester1[i] === Number(month)) {
        return 1;
      }
    }

    return 2;
  }

  ////////////////////////////////////////////////////////
  // Check and see if the rep picked the correct tier for the purchase
  ///////////////////////////////////////////////////////

  function isPurchaseAtCorrectTier() {
    //Get the previous semester and year
    if (thisSemester === 1) {
      PreviousSemester = 2;
      PreviousYear = thisYear - 1;
    } else {
      PreviousSemester = 1;
    }

    // Filter the orders based on their purchase date
    let filteredOrdersTotal = 0;

    for (let i = 0; i < company.orders.length; i++) {
      const purchaseYear = moment(company.orders[i][2]).format("YYYY");
      if (
        Number(PreviousYear) === Number(purchaseYear) &&
        PreviousSemester ===
          getSemesterFromMonth(moment(company.orders[i][2]).format("M"))
      ) {
        filteredOrdersTotal =
          filteredOrdersTotal + Number(company.orders[i][1]);
      }
    }

    // If new Semester - Set the tier from the previous semester spend
    if (filteredOrdersTotal === 0) {
      newSemester = true;
      tierFromLastSemester = "Elite";
    } else {
      if (filteredOrdersTotal < 25000) {
        tierFromLastSemester = "Elite";
      } else if (filteredOrdersTotal > 25000 && filteredOrdersTotal < 40000) {
        tierFromLastSemester = "Elite+";
      } else {
        tierFromLastSemester = "VIP";
      }
    }

    console.log(compareTier);

    // Return data
    if (tierFromLastSemester === compareTier) {
      return {
        message: "",
        warn: false,
        openingOrder: false,
      };
    } else {
      return {
        message:
          "We recommend the " +
          tierFromLastSemester +
          " tier for this purchase. You have chosen " +
          compareTier,
        warn: true,
        openingOrder: false,
      };
    }
  }

  ////////////////////////////////////////////////////////
  // Calculate the recommended tier for this semeseter using past purchases data from this semester
  ////////////////////////////////////////////////////////
  function calculateReccomendedTierForThisSemester() {
    let thisSemesterPurchases = 0;
    let firstPurchase = company.orders[0][1];

    for (let i = 0; i < company.orders.length; i++) {
      const purchaseYear = moment(company.orders[i][2]).format("YYYY");
      const purchaseSemester = getSemesterFromMonth(
        moment(company.orders[i][2]).format("M")
      );

      if (
        Number(moment(todaysDate).format("YYYY")) === Number(purchaseYear) &&
        purchaseSemester === thisSemester
      ) {
        thisSemesterPurchases =
          thisSemesterPurchases + Number(company.orders[i][1]);
      }
    }

    //Get the previous semester and year
    if (thisSemester === 1) {
      PreviousSemester = 2;
      PreviousYear = thisYear - 1;
    } else {
      PreviousSemester = 1;
    }

    // Filter the previous semester orders based on their purchase date
    let filteredOrdersTotal = 0;

    for (let i = 0; i < company.orders.length; i++) {
      const purchaseYear = moment(company.orders[i][2]).format("YYYY");

      if (
        Number(PreviousYear) === Number(purchaseYear) &&
        PreviousSemester ===
          getSemesterFromMonth(moment(company.orders[i][2]).format("M"))
      ) {
        filteredOrdersTotal =
          filteredOrdersTotal + Number(company.orders[i][1]);
      }
    }

    // If new Semester - Set the tier from the previous semester spend
    if (filteredOrdersTotal !== 0) {
      if (filteredOrdersTotal < 25000) {
        tierFromLastSemester = "Elite";
      } else if (filteredOrdersTotal > 25000 && filteredOrdersTotal < 40000) {
        tierFromLastSemester = "Elite+";
      } else {
        tierFromLastSemester = "VIP";
      }
    }

    let total = Number(thisSemesterPurchases) + orderTotal;

    if (tierFromLastSemester) {
      if (tierFromLastSemester === "Elite" && total < 15000) {
        reccomendedTier = "Elite";
      } else if (
        tierFromLastSemester === "Elite" &&
        total >= 15000 &&
        total < 25000
      ) {
        reccomendedTier = "Elite+";
      } else if (tierFromLastSemester === "Elite+" && total < 25000) {
        reccomendedTier = "Elite+";
      } else if (tierFromLastSemester === "Elite" && total >= 25000) {
        reccomendedTier = "VIP";
      } else {
        reccomendedTier = "VIP";
      }
    } else {
      if (firstPurchase < 10000) {
        reccomendedTier = "Elite";
      } else if (firstPurchase < 15000 && firstPurchase >= 10000) {
        reccomendedTier = "Elite+";
      } else if (firstPurchase >= 15000) {
        reccomendedTier = "VIP";
      }
    }

    if (reccomendedTier === compareTier) {
      return {
        message: "",
        warn: false,
        openingOrder: false,
      };
    } else {
      return {
        message:
          "We recommend the " +
          reccomendedTier +
          " tier for this purchase. You have chosen " +
          compareTier,
        warn: true,
        openingOrder: false,
      };
    }
  }

  ////////////////////////////////////////////////////////
  //Return the calculated values
  ////////////////////////////////////////////////////////
  return company.orders
    ? nonOpeningOrder(orderTotal)
    : openingOrder(orderTotal);
}
