import React from "react";
import Header from "../Header";
import DashboardNav from "../Dashboard/dashboardNav";
import Breadcrumb from "../breadcrumb";
import Benefit from "../View/components/benefit";
import BENEFITS from "../../Images/benefits.svg";
import BUSINESS_SUPPORT from "../../Images/businessSupport.svg";
import SHOPPING from "../../Images/shopping.svg";
import TEACHING from "../../Images/teaching.svg";
import IN_OFFICE from "../../Images/inOffice.svg";
import WEB_AUDIT from "../../Images/webAudit.svg";

const mainPanel = {
  paddingBottom: "60px",
  width: "100%",
  height: "100%",
  margin: "20px 10px",
};

const fullScreenSection = {
  margin: "auto",
  display: "flex",
  flexDirection: "row",
  height: "100vh",
};

export default function Benefits(props) {
  return (
    <div style={{ maxHeight: "100vh", overflowY: "hidden" }}>
      <Header style={{ height: "100%", position: "relative" }} />
      <div style={fullScreenSection}>
        <DashboardNav />
        <div className="dashboardMainPanel">
          <Breadcrumb title="Benefits" />
          <div style={mainPanel}>
            <div
              style={{
                display: "flex",
                maxWidth: "1200px",
                margin: "auto",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div style={{ maxWidth: "50%" }}>
                <h1>MedSpa Network Benefits</h1>
                <p>
                  Your accounts gain benefits as soon as the make their Opening
                  Order through MedSpa Network. As they make purchases they gain
                  more and more access to bigger and better benefits.
                </p>
                {/* <div
                  style={{ display: "flex", borderRadius: "10px 0px 0px 10px" }}
                >
                  <input
                    style={{ width: "70%" }}
                    placeholder="example@email.com"
                  />
                  <button
                    style={{ width: "30%", borderRadius: "0px 10px 10px 0px" }}
                  >
                    Send
                  </button>
                </div> */}
              </div>
              <div style={{ maxWidth: "50%", margin: "auto" }}>
                <img src={BENEFITS} width={"400px"} />
              </div>
            </div>
            <Benefit
              title={"Courses"}
              img={TEACHING}
              paragraph={`Your accounts recieve a link to Online Training Courses as soon as they sign up. Our trainings cover On-Lable subjects as well as Business Strategy and Digital Security`}
            />
            <Benefit
              title={"PAID - In Office Training"}
              img={IN_OFFICE}
              paragraph={`If online courses are not what your account is looking for, we have created an In Office Training program. Your account can either come to our office or we can go to theirs. Please contact Kolynn to get In Office Training setup.`}
            />
            <Benefit
              title={"PAID - Business Support"}
              img={BUSINESS_SUPPORT}
              paragraph={`Most companies fail within their first year due to not knowing how to run a profitable company. It is our goal to not only teach medical techniques but, to also business and finacial strategy.`}
            />
            <Benefit
              title={"Free Website and IT Security Audit"}
              img={WEB_AUDIT}
              paragraph={`We get it, Websites and IT Security are not the most fun topics, but they make or break your accounts practice. Your account recieves a free website audit on signup, and can request one of our IT professionals to come and audit their security. `}
            />
            <Benefit
              title={"Product Pricing"}
              img={SHOPPING}
              paragraph={`I mean, who doesnt like to save money...`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
