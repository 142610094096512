import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Header from "../Header";
import "./styles.css";
import DashboardNav from "../Dashboard/dashboardNav";


function NewCompany() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Company = useSelector(state => state.Company);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const mainPanel = {
    paddingBottom: "60px",
    width: "100%",
    backgroundColor: "#e3e3e3"
  };

  const {
    firstName,
    lastName,
    email,
    purchaserPhone,
    company,
    accountNumber,
    companyEmail,
    shippingZipcode,
    shippingState,
    shippingPhone,
    shippingCity,
    shippingAddress,
    attn,
    poBox,
    _id
  } = Company;

  useEffect(() => {
    setLoading(false);
    dispatch({
      type: "RESET_DATE"
    });
  }, [firstName]);

  const enterNewCompany = e => {
    e.preventDefault();
    console.log(details);
    axios
      .post("/api/accounts/editCompany", { details: details, _id: _id })
      .then(res => {
        navigate("/dashboard");
      });
  };

  return (
    <div
      style={{ maxHeight: "100vh", overflowY: "scroll" }}
    >
      <Header />
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          height: "100vh"
        }}
      >
        <DashboardNav />
        <div style={mainPanel}>
          <div
            style={{
              backgroundColor: "white",
              padding: "5px",
              margin: "0px"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                style={{
                  width: "100px",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid black"
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
              <p onClick={()=>{navigate("/dashboard")}} style={{ fontWeight: "800", margin: "0px", padding: "0px" }}>
                / Dashboard / Update Account / {company}
              </p>
            </div>
          </div>
      {loading ? (
        <div className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div style={{height: "100%", overflowY: "scroll"}}>
        <form
          style={{
            margin: "auto",
            textAlign: "left",
            paddingBottom: "60px",
            maxWidth: "900px",
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: "5px",
            marginTop: "10px"
          }}
        >
          <h1 className="inputFieldsHeaders" >
            Update Account
          </h1>
          <p>
            Only change the fields that need to be changed.
          </p>
          <div>
            <h3 className="inputFieldsHeaders">Purchasing Supervisor</h3>

            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  firstName === undefined || firstName === "" ? "#FFCFCF" : ""
              }}
              className="orderInputFields"
              placeholder={
                firstName === "N/A" ||
                firstName === "" ||
                firstName === undefined
                  ? "First Name"
                  : firstName
              }
              type="text"
              required
              id="firstName"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  lastName === undefined || lastName === "" ? "#FFCFCF" : ""
              }}
              className="orderInputFields"
              placeholder={
                lastName === "N/A" || lastName === "" || lastName === undefined
                  ? "Last Name"
                  : lastName
              }
              type="text"
              required
              id="lastName"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
          </div>
          <div>
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  email === undefined || email === "" ? "#FFCFCF" : ""
              }}
              className="orderInputFields"
              placeholder={
                email === "N/A" || email === "" || email === undefined
                  ? "Purchaser Email"
                  : email
              }
              type="email"
              required
              id="email"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  purchaserPhone === undefined || purchaserPhone === ""
                    ? "#FFCFCF"
                    : ""
              }}
              className="orderInputFields"
              placeholder={
                purchaserPhone === "N/A" ||
                purchaserPhone === "" ||
                purchaserPhone === undefined
                  ? "Purchaser Phone"
                  : purchaserPhone
              }
              type="number"
              required
              id="purchaserPhone"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            {/* //Shipping
             */}
          </div>
          <div>
            <h3 className="inputFieldsHeaders">Company Details</h3>
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  accountNumber === undefined || accountNumber === ""
                    ? "#FFCFCF"
                    : ""
              }}
              className="orderInputFields"
              placeholder={
                accountNumber === "N/A" ||
                accountNumber === "" ||
                accountNumber === undefined
                  ? "Account Number"
                  : accountNumber
              }
              type="number"
              required
              id="accountNumber"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  company === undefined || company === "" ? "#FFCFCF" : ""
              }}
              className="orderInputFields"
              placeholder={
                company === "N/A" || company === "" || company === undefined
                  ? "Company Name"
                  : company
              }
              type="text"
              required
              id="company"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  companyEmail === undefined || companyEmail === ""
                    ? "#FFCFCF"
                    : ""
              }}
              className="orderInputFields"
              placeholder={
                companyEmail === "N/A" ||
                companyEmail === "" ||
                companyEmail === undefined
                  ? "Company Email"
                  : companyEmail
              }
              type="text"
              required
              id="companyEmail"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  attn === undefined || attn === "" ? "#FFCFCF" : ""
              }}
              className="orderInputFields"
              placeholder={
                attn === "N/A" || attn === "" || attn === undefined
                  ? "Attn"
                  : attn
              }
              type="text"
              required
              id="attn"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  shippingPhone === undefined || shippingPhone === ""
                    ? "#FFCFCF"
                    : ""
              }}
              className="orderInputFields"
              placeholder={
                shippingPhone === "N/A" ||
                shippingPhone === "" ||
                shippingPhone === undefined
                  ? "Shipping Phone"
                  : shippingPhone
              }
              type="number"
              required
              id="shippingPhone"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  shippingAddress === undefined || shippingAddress === ""
                    ? "#FFCFCF"
                    : ""
              }}
              className="orderInputFields"
              placeholder={
                shippingAddress === "N/A" ||
                shippingAddress === "" ||
                shippingAddress === undefined
                  ? "Shipping Address"
                  : shippingAddress
              }
              required
              id="shippingAddress"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            <input
              style={{
                maxWidth: "900px",
                display: "block"
              }}
              className="orderInputFields"
              placeholder={
                poBox === "N/A" || poBox === "" || poBox === undefined
                  ? "po Box"
                  : poBox
              }
              id="poBox"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  shippingCity === undefined || shippingCity === ""
                    ? "#FFCFCF"
                    : ""
              }}
              className="orderInputFields"
              placeholder={
                shippingCity === "N/A" ||
                shippingCity === "" ||
                shippingCity === undefined
                  ? "Shipping City"
                  : shippingCity
              }
              required
              id="shippingCity"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />
            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  shippingState === undefined || shippingState === ""
                    ? "#FFCFCF"
                    : ""
              }}
              className="orderInputFields"
              placeholder={
                shippingState === "N/A" ||
                shippingState === "" ||
                shippingState === undefined
                  ? "Shipping State"
                  : shippingState
              }
              required
              id="shippingState"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            />

            <input
              style={{
                maxWidth: "900px",

                backgroundColor:
                  shippingZipcode === undefined || shippingZipcode === ""
                    ? "#FFCFCF"
                    : ""
              }}
              className="orderInputFields"
              name="zip"
              type="number"
              placeholder={
                shippingZipcode === "N/A" ||
                shippingZipcode === "" ||
                shippingZipcode === undefined
                  ? "Shipping Zipcode"
                  : shippingZipcode
              }
              required
              id="shippingZipcode"
              onChange={e => {
                setDetails({
                  ...details,
                  [e.target.id]: e.target.value
                });
              }}
            ></input>
            <button
              style={{
                maxWidth: "900px",
                margin: "auto",
                marginTop: "30px",
                textAlign: "center"
              }}
              onClick={e => {
                enterNewCompany(e);
              }}
            >
              Update Company Details
            </button>
          </div>
        </form>
        </div>
      )}
    </div>
    </div>
    </div>
  );
}

export default NewCompany;
