import React from "react";
import ALERT from "../../../Images/alertRed.png";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function reviewInputFields(props) {
  ////////////////////////////////////
  //  Styles
  ////////////////////////////////////

  const missingInfoStyle = {
    maxWidth: "25px",
    maxHeight: "25px",
    position: "absolute",
    top: "55%",
    right: "10px",
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        margin: '32px 0px'
      }}
    >
      {/* {(props.companyValue === "N/A" ||
        props.companyValue === "" ||
        props.companyValue === undefined) &&
      props.fieldName !== "poBox" ? (
        <img src={ALERT} alt="Missing Info" style={missingInfoStyle} />
      ) : null} */}
      <TextField
          required={props.required && props.fieldName != 'poBox'}
          error={!props.companyValue && props.fieldName != 'poBox'}
          id={props.fieldName}   
          sx={{width: '100%', margin: 'auto', color: 'white'}}       
          label={props.fieldName != 'poBox' ?  "Required" : "Optional"}
          color={props.fieldName ? "primary": 'error'}
          variant="outlined"
          focused
          defaultValue={
            props.companyValue === "N/A" ||
            props.companyValue === "" ||
            props.companyValue === undefined
              ? props.fieldName
                  .match(/([A-Z]?[^A-Z]*)/g)
                  .slice(0, -1)
                  .join(" ")
              : props.detailsValue
          }
          // value={
          //   props.companyValue === "N/A" ||
          //   props.companyValue === "" ||
          //   props.companyValue === undefined
          //     ? undefined
          //     : props.detailsValue
          // }
          onChange={(e) => {
            if (e.target.value === "") {
              props.setDetails({
                ...props.details,
                [e.target.id]: props.companyValue,
              });
            } else {
              props.setDetails({
                ...props.details,
                [e.target.id]: e.target.value,
              });
            }
          }}
        />
      {/* <input
        required={props.required ? true : false}
        className="orderInputFields"
        placeholder={
          props.companyValue === "N/A" ||
          props.companyValue === "" ||
          props.companyValue === undefined
            ? props.fieldName
                .match(/([A-Z]?[^A-Z]*)/g)
                .slice(0, -1)
                .join(" ")
            : props.detailsValue
        }
        type="text"
        id={props.fieldName}
        value={
          props.companyValue === "N/A" ||
          props.companyValue === "" ||
          props.companyValue === undefined
            ? undefined
            : props.detailsValue
        }
        onChange={(e) => {
          if (e.target.value === "") {
            props.setDetails({
              ...props.details,
              [e.target.id]: props.companyValue,
            });
          } else {
            props.setDetails({
              ...props.details,
              [e.target.id]: e.target.value,
            });
          }
        }}
      /> */}
    </Box>
  );
}
