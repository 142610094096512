const initialState = {
  email: "",
  phone: "",
  _id: "",
  repName: "",
  userType: ""
};

export default function User(state = initialState, action) {
  switch (action.type) {
    case "resetUser":
      return initialState;
    case "setUser":
      return {
        email: action.email,
        phone: action.phone,
        _id: action._id,
        repName: action.repName,
        userType: action.userType
      };
    default:
      return state;
  }
}
