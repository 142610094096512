import React from "react";
import Header from "../Header";
import DashboardNav from "../Dashboard/dashboardNav";
import AllProducts from "../../json/products.json";
import { intToDollars } from "../Functions/intConversions";

export default function Products() {
  let search = "";
  const mainPanel = {
    paddingBottom: "60px",
    width: "100%",
    backgroundColor: "#e3e3e3",
    overflowY: "scroll",
  };

  ////////////////////////////////////
  //Read the products from the JSON file and create a bunch of rows to contain it.
  ////////////////////////////////////
  const allProductsForSearch = AllProducts.map((headings) => {
    return headings[Object.keys(headings)[0]]
      .filter((item) => {
        if (search === "") {
          return item;
        } else if (
          item.name
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        ) {
          return item;
        } else return null;
      })
      .map((item, index) => {
        return (
          <tr key={index} className="productTableContentRow">
            <th className="productTableContent">{item.name}</th>
            <th className="productTableContent">
              {intToDollars(item.tierThreePrice)}
            </th>
            <th className="productTableContent">
              {intToDollars(item.tierFourPrice)}
            </th>
            <th className="productTableContent">
              {intToDollars(item.tierFivePrice)}
            </th>
          </tr>
        );
      });
  });

  return (
    <div style={{ maxHeight: "100vh", overflowY: "hidden" }}>
      <Header />
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
        }}
      >
        <DashboardNav />
        <div style={mainPanel}>
          <div
            style={{
              backgroundColor: "white",
              padding: "0px 10px",
              margin: "0px",
              zIndex: "99",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "800",
                margin: "0px",
                padding: "10px",
              }}
            >
              / Products
            </p>
          </div>
          <div>
            <table className="productTable">
              <thead className="productTableHeader">
                <tr>
                  <th className="productTableHeaderTitle">Name</th>
                  <th className="productTableHeaderTitle">Tier 3 Price</th>
                  <th className="productTableHeaderTitle">Tier 4 Price</th>
                  <th className="productTableHeaderTitle">Tier 5 Price</th>
                </tr>
              </thead>
              <tbody>{allProductsForSearch}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
