import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Header from "../Header";
import axios from "axios";
import AccountRow from "./components/accountsRow";
import "./styles.css";
import "./paginationStyles.css";
import ReactPaginate from "react-paginate";
import DashboardNav from "../Dashboard/dashboardNav";
import Modal from "react-modal";
import NewAccount from "../Tools/newAccount";
import { intToDollars } from "../Functions/intConversions";
import moment from "moment";

export default function Accounts() {
  var fileDownload = require("js-file-download");

  const [loading, setLoading] = useState(true);
  const [accountsData, setAccountsData] = useState([]);
  const [status, setStatus] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedRows, setSelection] = useState([]);
  let subtitle;

  Modal.setAppElement("#root");

  const mainPanel = {
    paddingBottom: "60px",
    width: "100%",
    backgroundColor: "white",
    overflowY: "scroll",
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#e3e3e3",
    },
  };

  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    axios
      .get("/api/accounts/getcompanieslist", {
        headers: {
          Authorization: localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        setAccountsData(res.data, setLoading(false));
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const endOffset = itemOffset + itemsPerPage;
    // setCurrentItems(accountsData.sort((a,b) => b.dateAdded - a.dateAdded).slice(itemOffset, endOffset));
    setCurrentItems(accountsData);
    setPageCount(
      Math.ceil(accountsData.length / itemsPerPage),
      setLoading(false)
    );
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [itemOffset, itemsPerPage, accountsData]);

  useEffect(() => {
    axios
      .get("/api/accounts/getcompanieslist", {
        headers: {
          Authorization: localStorage.getItem("authToken"),
        },
      })
      .then((res) => {
        setAccountsData(res.data);
      });
  }, [status]);

  // const resetStatus = () => {
  //   setLoading(true);
  //   setStatus(!status);
  // };

  // function Items({ currentItems }) {
  //   return currentItems.length > 0 ? (
  //     currentItems.map((account, index) => {
  //       return (
  //         <AccountRow
  //           key={index}
  //           props={{ account: account, index: index }}
  //           resetStatus={resetStatus}
  //           style={{ overflow: "hidden" }}
  //         />
  //       );
  //     })
  //   ) : (
  //     <div></div>
  //   );
  // }

  const accountExportForMSN = () => {
    axios
      .post(
        "/api/export/accountsDataForImport",
        { data: accountsData },
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(response.data, `accountExportForMSN${Date.now()}.xlsx`);
      });
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const usdPrice = {
    type: "number",
    width: 130,
    valueFormatter: ({ value }) => intToDollars(value),
    cellClassName: "font-tabular-nums",
  };

  const columns = [
    { field: "company", headerName: "Company", flex: 1 },
    {
      field: "grandTotal",
      headerName: "Grand Total",
      ...usdPrice,
      flex: 1,
    },
    {
      field: "semesterTotal",
      headerName: "Semester Total",
      ...usdPrice,
      flex: 1,
    },
    {
      field: "orders",
      headerName: "Last Purchase",
      flex: 1,
      sortable: true,
      type: "date",
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        try {
          return (
            <p>
              {moment(
                params.row.orders[params.row.orders.length - 1][2]
              ).format("MM/DD/YYYY")}
            </p>
          );
        } catch (err) {
          return;
        }
      },
      // <LinearProgress variant="determinate" value={progress} />
    },
    {
      field: "Ordering Supervisor",
      headerName: "Ordering Supervisor",
      description: "Ordering Supervisor",
      sortable: true,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];

  return (
    <div style={{ maxHeight: "100vh", overflowY: "hidden" }}>
      <Header />
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
        }}
      >
        <DashboardNav />
        <div style={mainPanel}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "30px",
                padding: "0px 30px",
              }}
            >
              <button style={{ width: "150px" }} onClick={openModal}>
                Add Account
              </button>

              <button
                className="filterButton"
                style={{ borderRadius: "5px" }}
                onClick={() => {
                  accountExportForMSN();
                }}
              >
                Export Excel
              </button>

              {/* <button style={{ width: "150px" }} onClick={openModal}>
          Add Accounts
        </button> */}
            </div>

            {loading === false ? (
              <div style={{ height: "90vh", width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row._id}
                  sx={{
                    ".MuiDataGrid-columnHeaderTitle": {
                      fontSize: "22px",
                      fontWeight: "800",
                    },
                  }}
                  rows={accountsData}
                  columns={columns}
                  rowsPerPageOptions={[
                    50,
                    100,
                    150,
                    200,
                    accountsData.length + 1,
                  ]}
                />
              </div>
            ) : (
              <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Add User"
              overlayClassName="Overlay"
            >
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Add Account</h2>
              <NewAccount />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
