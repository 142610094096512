import { intToDollars } from "./intConversions";

////////////////////////////////////
//  Create and send emails
////////////////////////////////////
export default function sendEmails(details, handleClick) {
  // //Setup the HTML table for the products at the bottom of the email.

  let emailCart = [];

  console.log(details);

  Object.keys(details.data.cart).forEach((item) => {
    if (details.data.cart[item] !== 0) {
      emailCart.push(`<html>
        <table width="600" style="border:1px solid #333; margin: auto">
          <tr>
            <td align="center">Product</td>
            <td align="center">Amount Ordered</td>
          </tr>
          <tr>
            <td align="center">${item}</td>
            <td align="center">${details.data.cart[item].ordered}</td>
          </tr>
        </table>
      </html>`);
    }
  });

  // Use the EMAILJS api to send an email out to everyone
  // details.data.repEmail,
  window.emailjs
    .send("service_k7n5kjk", "template_jxzu99e", {
      email_to: [
        details.data.email,
        details.data.repEmail,

        "Sales@medspanetwork.com",
      ],
      message: "Your order has shipped!",
      from_name: "Medspa Network Orders",
      //reply_to: "sales@medspanetwork.com",
      to_name: "Medspa Network",
      my_html: emailCart.join(""),
      company: details.data.company,
      trackingNumber: details.data.trackingNumber,
      shippingStatus: details.data.shippingStatus,
      _id: details.data._id,
      trackingLink:
        "https://www.fedex.com/fedextrack/?trknbr=" +
        details.data.trackingNumber,
      total: intToDollars(details.data.amount),
    })
    .then((res) => {
      if (details.data.shippingStatusChange === true) {
        window.location.reload();
      } else {
        emailCart = [];
      }
    })
    .catch((err) => {
      console.error(
        "Oh well, you failed. Here some thoughts on the error that occured:",
        err
      );
    });
}
