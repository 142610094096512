import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import logo from "../Images/mark_resize.png";
import darkLogo from "../Images/DarkVersion.png";
import "./App/App.css";
import "./App/hamburgers.css";
import UseAuth from "./App/auth";

export default function Header() {
  let [isActive, setIsActive] = useState(false);
  const { logout } = UseAuth();

  //MENU DISPLAY LOGIC
  //////////////////////////////////////
  if (isActive === true && document.getElementById("MenuPopup") != null) {
    document.getElementById("MenuPopup").style.display = "block";
  } else if (
    isActive === false &&
    document.getElementById("MenuPopup") != null
  ) {
    document.getElementById("MenuPopup").style.display = "none";
  }

  return (
    <div className="fullHeader greyTexture">
      <header className="App-header ">
        <Link to={"/dashboard"}>
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <button
            href=""
            id="hamburger"
            aria-label="Menu"
            className={
              isActive
                ? "hamburger hamburger--emphatic is-active"
                : "hamburger hamburger--emphatic"
            }
            type="button"
            onClick={() => {
              setIsActive(prevState => !prevState);
            }}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </header>

      <div className="MenuPopup" id="MenuPopup">
        <ul
          className="menuList"
          style={{
            listStyle: "none",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "space-evenly",
            margin: "0px",
            height: "100%",
            padding: "0px"
          }}
        >
          <li>
            <Link to={"/dashboard"} className="" id="order">
              Home
            </Link>
          </li>

          <li>
            <Link to="/contact" className="" id="contact">
              Contact Us
            </Link>
          </li>

          <li>
            <Link
              to="/"
              className=""
              id="contact"
              onClick={() => {
                localStorage.removeItem("userId");
                localStorage.removeItem("userType");
                localStorage.removeItem("authToken");

                logout();
              }}
            >
              Logout
            </Link>
          </li>

          <li style={{ textAlign: "center" }}>
            <img
              alt="darkLogo"
              src={darkLogo}
              style={{ maxWidth: "20%", textAlign: "center" }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
