import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import sendEmails from "../../Functions/emails";
import { intToDollars } from "../../Functions/intConversions";

export default function AdminTiles({
  details,
  index,
  trackingNumber,
  setTrackingNumber,
  confirmShipping,
  orderRecieptEmail,
  emailCart,
  handleClick,
}) {
  const [heldTrackingNumber, newTrackingNumber] = useState(trackingNumber);


  return (
    <div className="column" style={{ padding: "30px 0px" }}>
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid grey",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
        <div style={{ backgroundColor: "transparent" }}>
          {details.shippingStatus === undefined ||
          details.shippingStatus === "Pending" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "red",
                    borderRadius: "50%",
                    width: "15px",
                    height: "15px",
                    content: "",
                  }}
                />
                <p
                  style={{
                    color: "red",
                    height: "100%",
                    padding: "5px 10px",
                    fontSize: "18px",
                    fontWeight: "800",
                    margin: "0px",
                  }}
                >
                  Pending
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <input
                    className="orderInputFields"
                    placeholder="Tracking Number"
                    value={heldTrackingNumber}
                    style={{ maxWidth: "300px", marginTop: "0px" }}
                    onChange={(e) => newTrackingNumber(e.target.value)}
                  />
                  <button
                    id={index}
                    style={{
                      backgroundColor: "transparent",
                      color: "red",
                      maxWidth: "100px",
                      border: "1px red solid",
                      borderRadius: "5px",
                      textAlign: "center",
                      minHeight: "50px",
                    }}
                    onClick={() => {
                      document.getElementById(index).style.backgroundColor =
                        "grey";
                      document.getElementById(index).style.color = "white";
                      confirmShipping(details._id, heldTrackingNumber);
                    }}
                  >
                    Enter
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="shippedCircle" />
                <p className="shippedText">Shipped</p>
              </div>

              <p>
                Tracking Number:{" "}
                <a
                  href={
                    "https://www.fedex.com/fedextrack/?tracknumbers=" +
                    details.trackingNumber
                  }
                  target="blank"
                >
                  {details.trackingNumber}
                </a>
              </p>
            </div>
          )}
        </div>
        <div>
          <p style={{ fontWeight: "800", fontSize: "22px" }}>
            {details.company}
          </p>
        </div>
        <div>
          <p>{intToDollars(Number(details.amount))}</p>
        </div>

        <div>
          <p>{moment(details.date).format("MMMM Do YYYY, h:mm:ss a")}</p>
        </div>

        <div>
          <h3>Cart</h3>
        </div>

        <div>
          {Object.keys(details.cart).map((item,index)=>{
            return <p key={index}>{item}: {details.cart[item].ordered}</p>
          })}
        </div>

        <div style={{ borderTop: "1px solid lightgrey", marginTop: "30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <Link
              className="viewOrderDetailsAdminCenter"
              to={"/viewTransaction/" + details._id}
            >
              View Order Details
            </Link>

            {details.shippingStatus === undefined ||
            details.shippingStatus === "Pending" ? (
              <button
                className="resendTrackingEmail"
                style={{ backgroundColor: "salmon" }}
                onClick={() => {
                  orderRecieptEmail(details);
                }}
              >
                Resend Order Reciept
              </button>
            ) : (
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  className="resendTrackingEmail"
                  onClick={() => {
                    console.log(details, emailCart);
                    sendEmails(details, emailCart, handleClick);
                  }}
                >
                  Resend Tracking Email
                </button>
                <button
                  className="resendTrackingEmail"
                  style={{ backgroundColor: "salmon" }}
                  onClick={() => {
                    orderRecieptEmail(details);
                  }}
                >
                  Resend Order Reciept
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
