import React from "react";

export default function LoadAdminTiles() {
  return (
    <div>
      <div className="column">
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid grey",
            borderRadius: "5px",
            margin: "30px 10px",
            padding: "10px",
          }}
        >
          <div style={{ backgroundColor: "transparent" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "grey",
                    borderRadius: "50%",
                    width: "15px",
                    height: "15px",
                    content: "",
                  }}
                />
                <p
                  style={{
                    color: "grey",
                    height: "100%",
                    padding: "5px 10px",
                    fontSize: "18px",
                    fontWeight: "800",
                    margin: "0px",
                  }}
                >
                  Loading
                </p>
              </div>
            </div>
          </div>
          <div>
            <p style={{ fontWeight: "800", fontSize: "22px" }}>Loading</p>
          </div>
          <div>
            <p>Loading Details</p>
          </div>

          <div style={{ borderTop: "1px solid lightgrey", marginTop: "30px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className="viewOrderDetailsAdminCenterLoading"></div>
              <div className="resendTrackingEmailLoading"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
