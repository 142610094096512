import axios from "axios";
import React from "react";
import "./styles.css";

export default function UserRow(Props) {
  const {
    firstName,
    lastname,
    TBMEmail,
    TBMPhoneNumber,
    company,
    active,
    _id
  } = Props.props.user;

  function activation(_id) {
    axios
      .post("/api/users/changeActiveStatus", {
        _id: _id,
        active: !active
      })
      .then(res => {
        Props.resetStatus();
      });
  }

  return (
    <div
      className={Props.props.index % 2 === 0 ? "userRow white" : "userRow grey"}
    >
      <div style={{ width: "100%" }} className="dataTableCell">
        {firstName}
      </div>
      <div style={{ width: "100%" }} className="dataTableCell">
        {lastname}
      </div>
      <div style={{ width: "100%" }} className="dataTableCell">
        {TBMEmail}
      </div>
      <div style={{ width: "100%" }} className="dataTableCell">
        {TBMPhoneNumber}
      </div>
      <div style={{ width: "100%" }} className="dataTableCell">
        {company}
      </div>
      <div style={{ width: "100%" }}>
        {active ? (
          <label className="switch">
            <input
              type="checkbox"
              onClick={() => {
                activation(_id);
              }}
            />
            <span className="slider round"></span>
          </label>
        ) : (
          <label className="switch">
            <input
              type="checkbox"
              defaultChecked
              onClick={() => {
                activation(_id);
              }}
            />
            <span className="slider round"></span>
          </label>
        )}
      </div>
    </div>
  );
}
