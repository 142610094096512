import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function AccountsList({ company, index, selectCompany }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let missingInfo = false;

  return (
    <div
      className="dashboardAccountsListItem"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        padding: "10px",
        width: "100%",
        alignSelf: "center",
        backgroundColor: "white"
      }}
    >
      <div>
        <p style={{ fontWeight: "600" }}>{company.company}</p>
      </div>

      <div
        style={{
          maxWidth: "65%",
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        {missingInfo ? (
          <div style={{ display: "flex" }}>
            <p style={{ color: "#d2282e", fontSize: "12px" }}>
              This account is missing info
            </p>
            <button
              onClick={() => {
                selectCompany(company._id);
                dispatch({ type: "reset" });

                navigate("/editCompany");
              }}
              style={{
                backgroundColor: "transparent",
                color: "dimgrey",
                border: "solid dimgrey 1px",
                borderRadius: "5px",
                padding: "5px",
                margin: "5px",
                textDecoration: "none",
                fontSize: "12px",
                width: "100px"
              }}
            >
              Add now
            </button>
          </div>
        ) : (
          <div>
            <Link
              to="/order"
              onClick={() => {
                selectCompany(company._id);
                dispatch({ type: "reset" });
              }}
              style={{
                backgroundColor: "transparent",
                color: "#d2282e",
                border: "solid #d2282e 1px",
                borderRadius: "5px",
                padding: "5px",
                margin: "5px",
                textDecoration: "none"
              }}
            >
              Order
            </Link>
            <Link
              to={"/viewCompany/" + company._id}
              style={{
                backgroundColor: "dimgrey",
                color: "white",
                borderRadius: "5px",
                padding: "5px",
                margin: "5px",
                textDecoration: "none"
              }}
              onClick={() => {
                selectCompany(company._id);
                dispatch({ type: "reset" });
              }}
            >
              View
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
