import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import DashboardNav from "../Dashboard/dashboardNav";
import WEBINAR from "../../Images/clouds.jpg";

const mainPanel = {
  paddingBottom: "60px",
  width: "100%",
  backgroundColor: "#e3e3e3",
  height: "100%",
};

// import { Container } from './styles';

function Schedule() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("/api/events/events").then((res) => {
      console.log(res.data);
      setEvents(res.data);
      setLoading(false);
    });
  }, []);

  const eventList = events.map((event, index) => {
    let color;

    switch (event.type) {
      case "visit":
        color = "skyblue";
        break;

      case "webinar":
        color = "goldenrod";
        break;

      case "in-office":
        color = "#c32389";
        break;

      default:
        break;
    }

    return (
      <li
        key={index}
        style={{
          gap: "30px",
          borderBottom: "1px solid grey",
          flexDirection: "row",
          width: "100%",
          display: "flex",
          alignItems: "center",
          background: "white",
          borderLeft: "20px solid " + color,
        }}
      >
        <div
          style={{
            height: "100%",
            background: color,
            padding: "60px 0px",
            maxWidth: "30px",
          }}
        >
          <p style={{ transform: "rotate(-90deg)", width: "100%" }}>
            {/* {event.type} */}
          </p>
        </div>
        <div style={{ width: "33%", maxWidth: "200px" }}>
          <h1>{moment(event.startDate).format("MMMM D")}</h1>
        </div>
        <div>
          <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
            <h2 style={{ margin: "0px" }}>{event.title}</h2>
          </div>
          <div>
            <h4>
              {moment(event.startDate).format("MMMM D") +
                " - " +
                moment(event.endDate).format("MMMM D")}
            </h4>
          </div>
        </div>
      </li>
    );
  });

  return (
    <div>
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
        }}
      >
        <DashboardNav />

        <div className="dashboardMainPanel" id="adminPanel">
          <div
            style={{
              backgroundColor: "white",
              padding: "0px 10px",
              margin: "0px",
              zIndex: "99",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "800",
                margin: "0px",
                padding: "10px",
              }}
            >
              / Event Schedule
            </p>
          </div>
          <div style={mainPanel}>
            {loading ? (
              <h1>Loading</h1>
            ) : (
              <ul style={{ margin: "0px" }}>{eventList}</ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schedule;
