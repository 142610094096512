import React from "react";

export default function infoSquare(props) {
  const { int, edgeColor, text } = props;
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "white",
        border: '1px',
        borderColor: "#a3c3c7",
        borderStyle: "solid",
        margin: "0xp",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        color: edgeColor,
        padding: '30px 0px'
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "0px",
          flexDirection: "column",
          justifyContent: "flex-start",
          margin: "10px",
          color: "black",
          width: '100%'
        }}
      >
        <p style={{ margin: "0px", textAlign: "center", fontWeight: "400", fontSize: '28px' }}>
          {int}
        </p>
        <p style={{ margin: "0px", fontSize: "16px", textAlign: "center", color: edgeColor, fontWeight: '800' }}>
          {text}
        </p>
      </div>
    </div>
  );
}
